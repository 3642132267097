import { createTheme } from "@mui/material/styles";
import "../Styles/css/fonts.css";
import "../Styles/css/styles-UIUX.css";

export const theme = createTheme({
  typography: {
    fontFamily: '"Plus Jakarta Sans", sans-serif',
  },
  palette: {
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#e5007c",
    },
  },
});
