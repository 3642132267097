import { useMsal } from "@azure/msal-react";
import { useAtom } from "jotai";
import { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { userAtom } from "../../features/user/userAtom";

export default function Logout() {
  const { instance } = useMsal();
  let navigate = useNavigate();
  const [user, setUser] = useAtom(userAtom);
  useEffect(() => {
    try {
      instance
        .logoutPopup({
          postLogoutRedirectUri: "/",
          mainWindowRedirectUri: "/",
        })
        .finally(() => setUser({}));
      navigate("/");
    } catch (e) {
      navigate("/");
    }
  }, []);
  return;
}
