import {
  useGetCategoriasQuery,
  useGetLocacionesQuery,
  useCreateEventMutation,
  useGetFuentesSolicitantesQuery,
  useGetEntidadesQuery,
  useGetUsersGLPIQuery,
} from "../../features/api/apiYeapGo";

import {
  Autocomplete,
  Box,
  CircularProgress,
  Grid,
  TextField,
} from "@mui/material";
import { Cancel } from "@mui/icons-material";
import { useEffect, useState } from "react";
import swal from "sweetalert2";
import { useAtom } from "jotai";
import { userAtom } from "../../features/user/userAtom";
import AutocompleteSelect from "./Autocomplete";
import dayjs from "dayjs";
import {
  Impact,
  Priority,
  RolesAdministrador,
  Status,
  Type,
  Urgency,
} from "../../features/Types";
import RichTextEditor from "./RichTextEditor";

export const CreateEvent = () => {
  const [user] = useAtom(userAtom);
  const [send, setSend] = useState(false);
  const [entidad, setEntidad] = useState(-1);
  const { Token, entidad: entidadUser } = user;
  const [data, setData] = useState({
    solicitantes: [],
    asignados: [],
    observadores: [],
    Fecha: dayjs().format("YYYY-MM-DD HH:mm:ss"),
    FuenteSolicitante: "",
  });

  useEffect(() => {
    setEntidad(data.Entidad >= 0 ? data.Entidad : entidadUser);
  }, [data]);

  const [createEvent, { isSuccess, isError, isLoading }] =
    useCreateEventMutation();

  /* REST */

  const { data: Categorias } = useGetCategoriasQuery({
    Token,
    entidad: entidad,
  });
  const { data: Locaciones } = useGetLocacionesQuery({
    Token,
    entidad: entidad,
  });
  const { data: Fuentes } = useGetFuentesSolicitantesQuery({
    Token,
    entidad: entidad,
  });
  const { data: Entidades, isLoading: isLoadingEntidades } =
    useGetEntidadesQuery({
      Token,
    });
  const { data: UsersGLPI, isLoading: isLoadingUsersGLPI } =
    useGetUsersGLPIQuery({
      Token,
    });

  /* Logs */

  


  /* UseEffects */

  useEffect(() => {
    if (isSuccess) {
      setData({
        solicitantes: [],
        asignados: [],
        observadores: [],
        Fecha: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      });
      swal.fire({
        title: "Evento creado",
        icon: "success",
        position: "top",
        customClass: {
          confirmButton: "custom-swal-confirm-button-success",
        },
        width: "1000px",
      });
    }
    if (isError) {
      swal.fire({
        title: "No se ha podido crear el evento",
        icon: "error",
        text: "Valide que todos los campos hayan sido llenados correctamente",
        position: "top",
        customClass: {
          confirmButton: "custom-swal-confirm-button-error",
        },
        width: "1000px",
      });
    }
  }, [isSuccess, isError]);

  /* Handdles */
  const handleAddObservador = (event) => {
    let value = event.target.lastChild.data;
    let regexUser = /\(([^)]+)\)/;    
    value = value.match(regexUser)[1];
    if (value && !data.observadores?.includes(value)) {
      setData({
        ...data,
        observadores: [...data.observadores, value],
      });
    }
  };
  const handleRemoveObservador = (option) => {
    const updatedOptions = data.observadores.filter((item) => item !== option);
    setData({
      ...data,
      observadores: updatedOptions,
    });
  };
  const handleAddSolicitante = (event) => {
    // console.log("solicitante");
    let value = event.target.lastChild.data;
    let regexUser = /\(([^)]+)\)/;    
    value = value.match(regexUser)[1];
    // console.log(value);
    if (value && !data.solicitantes?.includes(value)) {
      setData({
        ...data,
        solicitantes: [...data.solicitantes, value],
      });
    }
  };
  const handleRemoveSolicitante = (option) => {
    const updatedOptions = data.solicitantes.filter((item) => item !== option);
    setData({
      ...data,
      solicitantes: updatedOptions,
    });
  };
  const handleAddAsignado = (event) => {
    let value = event.target.lastChild.data;
    let regexUser = /\(([^)]+)\)/;    
    value = value.match(regexUser)[1];
    if (value && !data.asignados?.includes(value)) {
      setData({
        ...data,
        asignados: [...data.asignados, value],
      });
    }
  };
  const handleRemoveAsignado = (option) => {
    const updatedOptions = data.asignados.filter((item) => item !== option);
    setData({
      ...data,
      asignados: updatedOptions,
    });
  };

  /* Functions */
  const Crear = () => {
    setSend(true);
    console.log(data);
    if (
      data.Fecha &&
      data.Content &&
      data.FuenteSolicitante !== null &&
      data.FuenteSolicitante > 0 &&
      data.Impact !== null &&
      data.Impact > 0 &&
      data.Priority !== null &&
      data.Priority > 0 &&
      data.Status !== null &&
      data.Status > 0 &&
      data.Type !== null &&
      data.Type > 0 &&
      data.Urgency !== null &&
      data.Urgency > 0 &&
      data.asignados.length > 0
    ) {
      createEvent({
        Token,
        body: {
          ...data,
          Fecha: dayjs(data.Fecha).format("YYYY-MM-DD HH:mm:ss"),
          Entidad: user.esMesaCompartida ? data.Entidad : user.entidad,
          id_observadores: UsersGLPI.filter((u) =>
            data.observadores.includes(u.name)
          ).map((u) => u.id),
          id_solicitantes: UsersGLPI.filter((u) =>
            data.solicitantes.includes(u.name)
          ).map((u) => u.id),
          id_asignados: UsersGLPI.filter((u) =>
            data.asignados.includes(u.name)
          ).map((u) => u.id),
        },
      })
        .unwrap()
        .then(() => {

          setSend(false);

          swal.fire({
            title: "Ticket Creado",
            icon: "success",
            position: "top",
            customClass: {
              confirmButton: "custom-swal-confirm-button-success",
            },
            width: "1000px",
          }).finally(() => setData({
            solicitantes: [],
            asignados: [],
            observadores: [],
            Fecha: dayjs().format("YYYY-MM-DD HH:mm:ss"),
          }));

        })
        .catch((err) => {
          swal.fire({
            title: "No se pudo crear el ticket",
            icon: "error",
            text: "Valide que todos los campos hayan sido llenados correctamente",
            position: "top",
            customClass: {
              confirmButton: "custom-swal-confirm-button-error",
            },
            width: "1000px",
          });
        });
    } else {
      swal.fire({
        title: "Hay que llenar todos los campos",
        icon: "error",
        text: "Valide que todos los campos hayan sido llenados correctamente",
        position: "top",
        customClass: {
          confirmButton: "custom-swal-confirm-button-error",
        },
        width: "1000px",
      });
    }
  };

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      {isLoadingUsersGLPI && isLoadingEntidades ? (
        <CircularProgress color="secondary" />
      ) : (
        <Box sx={{ flexGrow: 1, ml: 1, mt: 1 }}>
          <h5>Campos requeridos</h5>
          <form
            onSubmit={(event) => {
              event.preventDefault();
            }}
          >
            <div className="row">
              <div className="col-md-12">
                <div className="form-floating mb-3 ">
                  <input
                    type="text"
                    className={`form-control ${!data.TicketName && send && "is-invalid"
                      }`}
                    id="tb-ftitle"
                    placeholder="Ingrese el título del ticket"
                    value={data.TicketName || ""}
                    onChange={(e) =>
                      setData({ ...data, TicketName: e.target.value })
                    }
                  />
                  <label for="tb-fname">Título *</label>

                  <div className="invalid-feedback">
                    {"Debe llenar el título del ticket"}
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-floating mb-3">
                  <AutocompleteSelect
                    options={Status}
                    data={data}
                    setData={setData}
                    propiedad={"Status"}
                    label="Estado *"
                    requerido={send}
                  />
                </div>{" "}
              </div>
              <div className="col-md-3">
                <div className="form-floating mb-3">
                  <AutocompleteSelect
                    options={Urgency}
                    label="Urgencia *"
                    data={data}
                    setData={setData}
                    propiedad={"Urgency"}
                    requerido={send}
                  />
                </div>{" "}
              </div>
              <div className="col-md-3">
                <div className="form-floating mb-3">
                  <AutocompleteSelect
                    options={Impact}
                    label="Impacto *"
                    data={data}
                    setData={setData}
                    propiedad={"Impact"}
                    requerido={send}
                  />
                </div>{" "}
              </div>
              <div className="col-md-3">
                <div className="form-floating mb-3">
                  <AutocompleteSelect
                    options={Type}
                    label="Tipo *"
                    data={data}
                    setData={setData}
                    propiedad={"Type"}
                    requerido={send}
                  />{" "}
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-floating mb-3">
                  <AutocompleteSelect
                    options={Priority}
                    label="Prioridad *"
                    data={data}
                    setData={setData}
                    propiedad={"Priority"}
                    requerido={send}
                  />
                </div>{" "}
              </div>
              <div className="col-md-3">
                <div className="form-floating mb-3">
                  <label className="control-labe fs-2 mt-n2 mx-n2">
                    Fecha de apertura *
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    value={dayjs(data.Fecha).format("YYYY-MM-DD")}
                    onChange={(e) => {
                      setData({
                        ...data,
                        Fecha: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                {Categorias ? (
                  <AutocompleteSelect
                    options={Categorias || []}
                    label="Categoría *"
                    data={data}
                    setData={setData}
                    propiedad={"Categoria"}
                    requerido={send}
                  />
                ) : (
                  <></>
                )}
              </div>
              <div className="col-md-12">
                {!user?.esMesaCompartida &&
                  !RolesAdministrador.map((rol) => rol.completename).includes(
                    user?.Rol
                  ) ? (
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      disabled
                      className="form-control"
                      value={
                        Entidades.filter((e) => {
                          return e.id === user.entidad;
                        })[0]?.name
                      }
                      focused
                    />
                    <label for="tb-fname">Entidad *</label>
                  </div>
                ) : (
                  <>
                    {Entidades ? (
                      <div className="form-floating mb-3">
                        <AutocompleteSelect
                          options={Entidades}
                          label="Entidad *"
                          data={data}
                          setData={setData}
                          propiedad={"Entidad"}
                          requerido={send}
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </div>
              <div className="col-md-6">
                {Locaciones?.length > 0 ? (
                  <div className="form-floating mb-3">
                    <AutocompleteSelect
                      options={Locaciones}
                      label="Locación *"
                      data={data}
                      setData={setData}
                      propiedad={"location"}
                      requerido={send}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className="col-md-6">
                {Fuentes?.length > 0 ? (
                  <div className="form-floating mb-3">
                    <AutocompleteSelect
                      options={
                        Fuentes?.map((f) => {
                          return { ...f, completename: f.name };
                        }) || []
                      }
                      label="Fuente solicitante *"
                      data={data}
                      setData={setData}
                      propiedad={"FuenteSolicitante"}
                      requerido={send}
                    />
                  </div>
                ) : (
                  <></>
                )}{" "}
              </div>
              {UsersGLPI ? (              
                <>
                  <div className="col-md-6">
                    <div className="form-floating mb-3">
                      <Autocomplete
                        multiple
                        options={
                          UsersGLPI
                            .filter(user => user.name)
                            .map(user => (`${user.firstname ?? ""} ${user.realname ?? ""} (${user.name})`))
                            .sort((a, b) => a.localeCompare(b)) || []
                        }
                        value={data.solicitantes}
                        onChange={handleAddSolicitante}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="solicitantes"
                            placeholder="solicitantes"
                            onKeyDown={(event) => {
                              if (event.key === "Enter") {
                                handleAddSolicitante(event);
                                event.preventDefault();
                              }
                            }}
                          />
                        )}
                        renderTags={(value) =>
                          value.map((option) => (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                borderColor: "#e5007c",
                                backgroundColor: "#FFB8DE",
                                marginRight: "2%",
                                padding: "1%",
                                borderRadius: "10%",
                              }}
                              key={option}
                            >
                              {option + "  "}
                              <button
                                style={{
                                  background: "none",
                                  border: "none",
                                }}
                                onClick={() => handleRemoveSolicitante(option)}
                              >
                                <Cancel style={{ fontSize: "20px" }} />
                              </button>
                            </div>
                          ))
                        }
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-floating mb-3">
                      <Autocomplete
                        multiple
                        options={
                          UsersGLPI
                            .filter(user => user.name)
                            .map(user => (`${user.firstname ?? ""} ${user.realname ?? ""} (${user.name})`))
                            .sort((a, b) => a.localeCompare(b)) || []
                        }
                        value={data.observadores}
                        onChange={handleAddObservador}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="observadores"
                            placeholder="observadores"
                            onKeyDown={(event) => {
                              if (event.key === "Enter") {
                                handleAddObservador(event);
                                event.preventDefault();
                              }
                            }}
                          />
                        )}
                        renderTags={(value) =>
                          value.map((option) => (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                borderColor: "#e5007c",
                                backgroundColor: "#FFB8DE",
                                marginRight: "2%",
                                padding: "1%",
                                borderRadius: "10%",
                              }}
                              key={option}
                            >
                              {option + "  "}
                              <button
                                style={{
                                  background: "none",
                                  border: "none",
                                }}
                                onClick={() => handleRemoveObservador(option)}
                              >
                                <Cancel style={{ fontSize: "20px" }} />
                              </button>
                            </div>
                          ))
                        }
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-floating mb-3">
                      <Autocomplete
                        multiple
                        options={
                          UsersGLPI
                            .filter(user => user.name)
                            .map(user => (`${user.firstname ?? ""} ${user.realname ?? ""} (${user.name})`))
                            .sort((a, b) => a.localeCompare(b)) || []
                        }
                        value={data.asignados}
                        onChange={handleAddAsignado}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="asignados *"
                            placeholder="asignados"
                            error={!data.asignados.length > 0 && send}
                            helperText={
                              !data.asignados.length > 0 &&
                              send &&
                              "Este campo es obligatorio."
                            }
                            onKeyDown={(event) => {
                              if (event.key === "Enter") {
                                handleAddAsignado(event);
                                event.preventDefault();
                              }
                            }}
                          />
                        )}
                        renderTags={(value) =>
                          value.map((option) => (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                borderColor: "#e5007c",
                                backgroundColor: "#FFB8DE",
                                marginRight: "2%",
                                padding: "1%",
                                borderRadius: "10%",
                              }}
                              key={option}
                            >
                              {option + "  "}
                              <button
                                style={{
                                  background: "none",
                                  border: "none",
                                }}
                                onClick={() => handleRemoveAsignado(option)}
                              >
                                <Cancel style={{ fontSize: "20px" }} />
                              </button>
                            </div>
                          ))
                        }
                      />
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
              <div className={`col-12 form-floating mb-3 `}>
                <div
                  style={
                    send && (!data.Content || data?.Content?.trim() === "")
                      ? { color: "#d32f2f" }
                      : {}
                  }
                >
                  Contenido*
                </div>

                <RichTextEditor
                  required={send}
                  id="tb-content" // Asigna un ID único para el atributo "for" de la etiqueta label
                  value={data.Content}
                  onChange={(value) => setData({ ...data, Content: value })}
                />
              </div>

              <div className="col-12">
                <div className="d-md-flex align-items-center mt-3">
                  <div className="ms-auto mt-3 mt-md-0">
                    <a
                      type=""
                      className="no-bg btn rounded-pill btn-light-info px-3"
                      data-bs-dismiss="modal"
                      onClick={() => {
                        setSend(false);
                      }}
                    >
                      <i className="ti ti-arrow-back me-2 fs-4"></i> Volver{" "}
                    </a>
                    <button
                      type="submit"
                      disabled={isLoading}
                      onClick={Crear}
                      className="btn btn-primary font-medium rounded-pill px-4"
                    >
                      <div className="d-flex align-items-center">
                        <i className="ti ti-calendar-plus me-2 fs-4"></i>
                        Crear Ticket
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Box>
      )}
    </Grid>
  );
};
