import React, { useEffect } from "react";
import swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import clsx from "clsx";
import * as XLSX from "xlsx";
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";
import {
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import {
  CircularProgress,
  Grid,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  InputAdornment,
  List,
  ListItemText,
  ListItem,
  Fab,
} from "@mui/material";
import {
  useGetUsersQuery,
  useDeleteUserMutation,
  useActiveUserMutation,
  useGetUsersLogsQuery,
  useChangeUserDesktopMutation,
} from "../../features/api/apiYeapGo";
import {
  CheckCircleOutline,
  DisplaySettings,
  DisplaySettingsOutlined,
  Download,
  HighlightOffOutlined,
  ListAlt,
  ShortText,
} from "@mui/icons-material";
import { useAtom } from "jotai";
import { userAtom } from "../../features/user/userAtom";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../features/msal/authConfig";
import { listUsers } from "../../features/msal/graph";
import { RolesAdministrador } from "../../features/Types";

export const Users = () => {
  const [user] = useAtom(userAtom);
  const { Token, Name } = user;
  const { instance, accounts } = useMsal();
  const Auth = Token;
  let navigate = useNavigate();

  /* REST */
  const { data, isLoading } = useGetUsersQuery({ Token: Auth });
  const [deleteUser] = useDeleteUserMutation();
  const [activeUser] = useActiveUserMutation();
  const [changeUser] = useChangeUserDesktopMutation();
  const { data: userLogs } = useGetUsersLogsQuery({
    Token,
  });

  const [dataUser, setDataUser] = useState({
    usuario: "",
    password: "",
    rol: "",
    nombres_apellidos: "",
    estado: "",
    entidad: "",
  });
  const [openLogs, setOpenLogs] = useState(false);

  /* Formularios */
  const [openDesactivar, setOpenDesactivar] = React.useState(false);
  const [openConfirmation, setOpenConfirmation] = React.useState(false);
  const [openDesktopConfirmation, setOpenDesktopConfirmation] =
    React.useState(false);

  /* Logs */
  // console.log("userLogs");
  // console.log(userLogs);

  /* useEffects */
  React.useEffect(() => {
    if (!user.Token) {
      navigate("login");
    } else if (!user?.modulos?.includes("Gestión de usuarios")) {
      swal.fire({
        title: "Sin permisos suficientes",
        icon: "error",
        text: "No tiene los permisos suficientes para gestionar usuarios",
        position: "top",
        customClass: {
          confirmButton: "custom-swal-confirm-button-error",
        },
        width: "1000px",
      });
      navigate("/");
    }
  }, [user]);
  useEffect(() => {
    ListUsersAD();
  }, [instance, accounts]);
  useEffect(() => {
    ListUsersAD();
  }, []);
  function ListUsersAD() {
    // Silently acquires an access token which is then attached to a request for MS Graph data
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        listUsers(response.accessToken).then(async (re) => {});
      });
    return "f";
  }

  /* table columns */
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 70,
      renderCell: (params) => (
        <div style={{ color: "#cd0573" }}>{params.value}</div>
      ),
    },
    {
      field: "usuario",
      headerName: "Usuario",
      width: 120,
    },

    {
      field: "nombres_apellidos",
      headerName: "Nombres",
      width: 200,
    },
    {
      field: "NombreEntidad",
      headerName: "Entidad",
      width: 150,
    },
    {
      field: "rol",
      headerName: "Rol",
      width: 200,
    },
    {
      field: "actions2",
      headerName: "Mesa",
      width: 160,
      sortable: false,
      renderCell: (params) => {
        const cambiarMesa = (e) => {
          e.stopPropagation();
          setOpenDesktopConfirmation(true);
          setDataUser({
            ...dataUser,
            id: params.row.id,
            entidad: params.row.entidad,
            estado: params.row.estado,
            nombres_apellidos: params.row.nombres_apellidos,
            usuario: params.row.usuario,
            rol: params.row.rol,
            mofidicador: Name,
            esMesaCompartida: params.row.esMesaCompartida,
          });
        };
        return (
          <>
            {RolesAdministrador.map((rol) => rol.completename).includes(
              params.row.rol
            ) ? (
              <> No Aplica</>
            ) : (
              <>
                {params.row.esMesaCompartida === 1 ? (
                  <Button
                    className="MuiButton-textDark"
                    variant="text"
                    color="primary"
                    startIcon={<DisplaySettings />}
                    onClick={cambiarMesa}
                  >
                    MESA COMPARTIDA
                  </Button>
                ) : (
                  <Button
                    variant="text"
                    color="secondary"
                    startIcon={<DisplaySettingsOutlined />}
                    onClick={cambiarMesa}
                  >
                    MESA DEDICADA
                  </Button>
                )}
              </>
            )}
          </>
        );
      },
    },
    {
      field: "estado",
      headerName: "Estado",
      width: 100,
      cellClassName: (params) => {
        if (params.value == null) {
          return "";
        }
        return clsx("super-app", {
          active: params.value === "ACTIVO",
          inactive: params.value === "INACTIVO",
        });
      },
    },

    {
      field: "actions",
      headerName: "Acciones",
      width: 160,
      sortable: false,
      renderCell: (params) => {
        const eliminarUsuario = (e) => {
          e.stopPropagation();
          setOpenDesactivar(true);
          setDataUser({
            ...dataUser,
            id: params.row.id,
            entidad: params.row.entidad,
            estado: params.row.estado,
            nombres_apellidos: params.row.nombres_apellidos,
            usuario: params.row.usuario,
            rol: params.row.rol,
            deshabilitador: Name,
          });
        };
        const activarUsuario = (e) => {
          e.stopPropagation();
          setOpenConfirmation(true);
          setDataUser({
            ...dataUser,
            id: params.row.id,
            entidad: params.row.entidad,
            estado: params.row.estado,
            nombres_apellidos: params.row.nombres_apellidos,
            usuario: params.row.usuario,
            rol: params.row.rol,
            fechaInicio: params.row.fecha_inicio_deshabilitado,
            fechaFin: params.row.fecha_fin_deshabilitado,
            observacion: params.row.comentario_deshabilitado,
            habilitador: Name,
          });
        };
        return (
          <>
            {params.row.estado === "ACTIVO" ? (
              <Button
                variant="text"
                color="primary"
                startIcon={<HighlightOffOutlined />}
                onClick={eliminarUsuario}
              >
                DESHABILITAR
              </Button>
            ) : (
              <Button
                className="MuiButton-textDark"
                variant="text"
                color="secondary"
                startIcon={<CheckCircleOutline />}
                onClick={activarUsuario}
              >
                HABILITAR
              </Button>
            )}
          </>
        );
      },
    },
    {
      field: "fecha_inicio_deshabilitado",
      headerName: "Deshabilitado desde",
      width: 150,
      renderCell: (params) => {
        if (params.value) {
          const fecha = new Date(params.value);
          const dia = fecha.getUTCDate().toString().padStart(2, "0");
          const mes = (fecha.getUTCMonth() + 1).toString().padStart(2, "0");
          const año = fecha.getUTCFullYear().toString();
          return `${dia}-${mes}-${año}`;
        }
      },
    },
    {
      field: "fecha_fin_deshabilitado",
      headerName: "Deshabilitado hasta",
      width: 150,
      renderCell: (params) => {
        if (params.value) {
          const fecha = new Date(params.value);
          const dia = fecha.getUTCDate().toString().padStart(2, "0");
          const mes = (fecha.getUTCMonth() + 1).toString().padStart(2, "0");
          const año = fecha.getUTCFullYear().toString();
          return `${dia}-${mes}-${año}`;
        }
      },
    },
    {
      field: "comentario_deshabilitado",
      headerName: "Observacion",
      width: 150,
    },
  ];

  /* handles */
  const handleClose = (action) => {
    setDataUser({});
    setOpenDesactivar(false);
  };

  const handleCloseConfirmation = (action) => {
    setOpenConfirmation(false);
    setDataUser({});
  };
  const handleCloseDesktopConfirmation = (action) => {
    setOpenDesktopConfirmation(false);
    setDataUser({});
  };

  const handleDesactivarUsuario = () => {
    if (validateForm()) {
      setOpenDesactivar(false);
      setOpenConfirmation(true);
    } else {
      swal.fire({
        title: "Deben llenarse todos los campos",
        icon: "error",
        position: "top",
        customClass: {
          confirmButton: "custom-swal-confirm-button-error",
        },
        width: "1000px",
      });
    }
  };
  const handleDesactivarUsuarioConfirmation = () => {
    deleteUser({ Token: Auth, body: dataUser })
      .unwrap()
      .then(() => {
        swal.fire({
          title: "Usuario modificado correctamente",
          icon: "success",
          position: "top",
          customClass: {
            confirmButton: "custom-swal-confirm-button-success",
          },
          width: "1000px",
        });
      })
      .catch((err) => {
        swal.fire({
          title: "No se pudo desactivar el usuario",
          icon: "error",
          position: "top",
          customClass: {
            confirmButton: "custom-swal-confirm-button-error",
          },
          width: "1000px",
        });
      });
    handleCloseConfirmation();
  };

  const handleActivarUsuarioConfirmation = () => {
    activeUser({ Token: Auth, body: dataUser })
      .unwrap()
      .then(() => {
        swal.fire({
          title: "Usuario modificado correctamente",
          icon: "success",
          position: "top",
          customClass: {
            confirmButton: "custom-swal-confirm-button-success",
          },
          width: "1000px",
        });
      })
      .catch((err) => {
        swal.fire({
          title: "No se pudo activar el usuario",
          icon: "error",
          position: "top",
          customClass: {
            confirmButton: "custom-swal-confirm-button-error",
          },
          width: "1000px",
        });
      });
    handleCloseConfirmation();
  };

  const handleCambiarMesa = () => {
    changeUser({ Token: Auth, body: dataUser })
      .unwrap()
      .then(() => {
        swal.fire({
          title: "Usuario modificado correctamente",
          icon: "success",
          position: "top",
          customClass: {
            confirmButton: "custom-swal-confirm-button-success",
          },
          width: "1000px",
        });
      })
      .catch((err) => {
        swal.fire({
          title: "No se pudo modificar el usuario",
          icon: "error",
          position: "top",
          customClass: {
            confirmButton: "custom-swal-confirm-button-error",
          },
          width: "1000px",
        });
      });
    handleCloseDesktopConfirmation();
  };

  const handleCloseLogs = () => {
    setOpenLogs(false);
  };

  /* Validaciones */
  const validateForm = () => {
    if (
      dataUser.observacion === "" ||
      !dataUser.observacion ||
      dataUser.fechaInicio === "" ||
      !dataUser.fechaInicio ||
      dataUser.fechaFin === "" ||
      !dataUser.fechaFin
    ) {
      return false;
    } else {
      return true;
    }
  };

  /* Dowload Report */
  const excelFileName = "Reporte Acciones Usuarios.xlsx";

  const generateExcelFile = () => {
    // Eliminar la columna "id" de los datos
    const updatedData = userLogs?.map(({ id, ...rest }) => rest);

    // Cambiar los nombres de las columnas
    const columnNames = {
      fecha: "Fecha",
      usuario: "Usuario",
      nombres_apellidos: "Nombres",
      accion: "Acción",
      usuario_admin: "Usuario Que Realizó La Acción",
      comentario: "Comentario",
    };
    // Crear una nueva matriz de objetos con los nombres de columna actualizados
    const updatedDataWithRenamedColumns = updatedData.map((item) => {
      const updatedItem = {};
      for (const key in item) {
        if (columnNames.hasOwnProperty(key)) {
          updatedItem[columnNames[key]] = item[key];
        }
      }
      return updatedItem;
    });

    // Crear el libro de Excel y la hoja de cálculo
    const worksheet = XLSX.utils.json_to_sheet(updatedDataWithRenamedColumns);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Reporte Usuarios");

    // Guardar y descargar el archivo de Excel
    XLSX.writeFile(workbook, excelFileName);
  };

  return (
    <>
      <Grid
        container
        direction="row"
        spacing={2}
        justifyContent="flex-start"
        alignItems="center"
      >
        <div className="card shadow-none position-relative overflow-hidden mt-3 mb-0">
          <div className="card-body px-4 py-3 overflow-auto">
            <h4 className="fw-semibold mb-1">Gestión de Usuarios</h4>
          </div>
        </div>

        {/* Tabla */}
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            height: 300,
            width: "100%",
            "& .super-app-theme--cell": {
              backgroundColor: "rgba(224, 183, 60, 0.3)",
            },
            "& .super-app.inactive": {
              color: "#e5007c",
            },
          }}
        >
          {isLoading ? (
            <CircularProgress color="secondary" />
          ) : (
            <>
              <DataGrid
                localeText={{
                  noRowsLabel: "No se ha encontrado datos.",
                  noResultsOverlayLabel: "No se ha encontrado ningún resultado",
                  toolbarColumns: "Columnas",
                  toolbarColumnsLabel: "Seleccionar columnas",
                  toolbarDensity: "Densidad",
                  toolbarDensityComfortable: "Comodo",
                  toolbarDensityStandard: "Estandar",
                  toolbarDensityCompact: "Compacto",
                  toolbarFilters: "Filtros",
                  toolbarFiltersLabel: "Ver filtros",
                  toolbarFiltersTooltipHide: "Quitar filtros",
                  toolbarFiltersTooltipShow: "Ver filtros",
                }}
                columns={columns}
                getRowClassName={(params) => `row-color`}
                sx={{ mb: "5%" }}
                autoHeight
                getRowHeight={() => "auto"}
                rows={data || []}
                pageSize={15}
                disableSelectionOnClick
                components={{
                  Toolbar: () => {
                    return (
                      <GridToolbarContainer>
                        <GridToolbarColumnsButton></GridToolbarColumnsButton>
                        <GridToolbarDensitySelector></GridToolbarDensitySelector>
                        <GridToolbarFilterButton></GridToolbarFilterButton>
                      </GridToolbarContainer>
                    );
                  },
                }}
                getRowId={(row) => row.id}
                // onSelectionModelChange={(e) => setEventsID(e)}
              />
            </>
          )}
        </Grid>
        {/* Historial Usuarios */}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Dialog
              fullWidth
              maxWidth={"xl"}
              open={openLogs}
              onClose={handleCloseLogs}
              PaperProps={{
                style: {
                  minWidth: "50%",
                  borderRadius: "20px",
                  position: "fixed",
                  top: "10px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  overflow: "hidden",
                },
              }}
            >
              <DialogTitle style={{ textAlign: "center", fontSize: "24px" }}>
                Historial de Acciones de Usuarios
              </DialogTitle>
              <DialogContent style={{ height: "calc(100% - 56px)" }}>
                {userLogs ? (
                  <List style={{ maxHeight: "100%", overflow: "auto" }}>
                    {userLogs.map((usuario, index) => (
                      <ListItem key={index}>
                        <ListItemText
                          primary={`${usuario.fecha.split("T")[0]} / ${
                            usuario.fecha.split("T")[1].split(".")[0]
                          } - ${usuario.nombres_apellidos}`}
                          secondary={`${usuario.accion} por ${usuario.usuario_admin}- ${usuario.comentario}`}
                        />
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  <></>
                )}
              </DialogContent>
              <DialogActions>
                <div className="col-12">
                  <div className="d-md-flex align-items-center">
                    <div className="ms-auto mt-md-0">
                      <Button
                        variant="contained"
                        color="secondary"
                        className="btn btn-primary font-medium rounded-pill px-4"
                        onClick={handleCloseLogs}
                        style={{ textTransform: "none" }}
                      >
                        <div className="d-flex align-items-center">
                          <i class="ti ti-x me-2 fs-4"></i>
                          Cerrar
                        </div>
                      </Button>
                    </div>
                  </div>
                </div>
              </DialogActions>
            </Dialog>
          </Grid>
        </Grid>

        {/* Formularios */}
        <Dialog
          open={openDesactivar}
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          PaperProps={{
            style: {
              minWidth: "60%",
              borderRadius: "20px",
              position: "fixed",
              top: "20px",
              left: "50%",
              transform: "translateX(-50%)",
              overflow: "hidden",
            },
          }}
        >
          <DialogTitle style={{ textAlign: "center", fontSize: "24px" }}>
            Deshabilitar Usuario
          </DialogTitle>
          <DialogContent style={{ cursor: "pointer" }}>
            <Grid container spacing={2}>
              <Grid item xs={6} sx={{ marginTop: "2%" }}>
                <TextField
                  fullWidth
                  required
                  type="date"
                  onChange={(e) =>
                    setDataUser({
                      ...dataUser,
                      fechaInicio: e.target.value,
                    })
                  }
                  label="Fecha de inicio"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  classes={{
                    root: "pborder",
                  }}
                />
              </Grid>
              <Grid item xs={6} sx={{ marginTop: "2%" }}>
                <TextField
                  fullWidth
                  required
                  type="date"
                  onChange={(e) =>
                    setDataUser({
                      ...dataUser,
                      fechaFin: e.target.value,
                    })
                  }
                  label="Fecha final"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  classes={{
                    root: "pborder",
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  marginTop: "2%",
                }}
              >
                <TextField
                  fullWidth
                  required
                  multiline
                  rows={3}
                  onChange={(e) =>
                    setDataUser({
                      ...dataUser,
                      observacion: e.target.value,
                    })
                  }
                  label="Observación"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <ShortText />
                      </InputAdornment>
                    ),
                  }}
                  classes={{
                    root: "pborder",
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ marginLeft: "3%", marginRight: "3%" }}>
            <div className="col-12">
              <div className="d-md-flex align-items-center mt-3">
                <div className="ms-auto mt-3 mt-md-0">
                  <Button
                    onClick={handleClose}
                    style={{
                      textTransform: "none",
                      backgroundColor: "#eef5fe",
                      color: "black !important",
                    }}
                    className="no-bg btn rounded-pill btn-light-info px-3 MuiButton-textDark"
                  >
                    <i className="ti ti-arrow-back me-2 fs-4"></i> Cancelar
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    className="btn btn-primary font-medium rounded-pill px-4"
                    onClick={handleDesactivarUsuario}
                    style={{ textTransform: "none" }}
                  >
                    <div className="d-flex align-items-center">
                      <i class="ti ti-user-x me-2 fs-4"></i>
                      Deshabilitar Usuario
                    </div>
                  </Button>
                </div>
              </div>
            </div>
          </DialogActions>
        </Dialog>

        {/* Confirmation Dialog */}
        <Dialog
          open={openConfirmation}
          onClose={handleCloseConfirmation}
          aria-labelledby="customized-dialog-title"
          PaperProps={{
            style: {
              minWidth: "60%",
              borderRadius: "20px",
              position: "fixed",
              top: "20px",
              left: "50%",
              transform: "translateX(-50%)",
              overflow: "hidden",
            },
          }}
        >
          <DialogTitle style={{ textAlign: "center", fontSize: "24px" }}>
            Confirmar Acción
          </DialogTitle>
          <DialogContent style={{ cursor: "pointer", minHeight: "200px" }}>
            <Grid container spacing={2}>
              {dataUser.estado === "ACTIVO" ? (
                <>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      marginTop: "2%",
                    }}
                  >
                    <Typography variant="body1">
                      Se desactivará el usuario{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {" "}
                        {dataUser.nombres_apellidos}
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1">
                      El usuario estará desactivado desde{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {dataUser.fechaInicio}{" "}
                      </span>
                      hasta{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {dataUser.fechaFin}
                      </span>
                    </Typography>
                  </Grid>
                </>
              ) : (
                <>
                  {" "}
                  <Grid
                    item
                    xs={12}
                    sx={{
                      marginTop: "2%",
                    }}
                  >
                    <Typography variant="body1">
                      Se Activará el usuario:{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {dataUser.nombres_apellidos}
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1">
                      El usuario esta desactivado hasta{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {" "}
                        {dataUser.fechaFin?.split("T")[0]}
                      </span>
                    </Typography>
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <Typography variant="body1">
                  Observación: {dataUser.observacion}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" style={{ fontWeight: "bold" }}>
                  {" "}
                  Desea continuar?
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ marginLeft: "3%", marginRight: "3%" }}>
            <div className="col-12">
              <div className="d-md-flex align-items-center">
                <div className="ms-auto mt-md-0">
                  <Button
                    onClick={handleCloseConfirmation}
                    color="primary"
                    style={{
                      textTransform: "none",
                      backgroundColor: "#eef5fe",
                      color: "black !important",
                    }}
                    className="no-bg btn rounded-pill btn-light-info px-3 MuiButton-textDark"
                  >
                    <i className="ti ti-x me-2 fs-4"></i> Cancelar
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    className="btn btn-primary font-medium rounded-pill px-4"
                    onClick={
                      dataUser.estado === "ACTIVO"
                        ? handleDesactivarUsuarioConfirmation
                        : handleActivarUsuarioConfirmation
                    }
                    style={{ textTransform: "none" }}
                  >
                    <div className="d-flex align-items-center">
                      <i class="ti ti-check me-2 fs-4"></i>
                      Confirmar
                    </div>
                  </Button>
                </div>
              </div>
            </div>
          </DialogActions>
        </Dialog>

        {/* Confirmation Change Desktop Dialog */}
        <Dialog
          open={openDesktopConfirmation}
          onClose={handleCloseDesktopConfirmation}
          aria-labelledby="customized-dialog-title"
          PaperProps={{
            style: {
              minWidth: "60%",
              borderRadius: "20px",
              position: "fixed",
              top: "20px",
              left: "50%",
              transform: "translateX(-50%)",
              overflow: "hidden",
            },
          }}
        >
          <DialogTitle style={{ textAlign: "center" }}>
            Confirmar Cambio de Mesa
          </DialogTitle>
          <DialogContent style={{ cursor: "pointer", minHeight: "200px" }}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sx={{
                  marginTop: "2%",
                }}
              >
                <Typography variant="body1">
                  Se cambiará el usuario{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    {dataUser.nombres_apellidos}
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  El usuario pasará de:{" "}
                  {dataUser.esMesaCompartida === 1 ? (
                    <span style={{ fontWeight: "bold" }}>Mesa Compartida</span>
                  ) : (
                    <span style={{ fontWeight: "bold" }}>Mesa Dedicada</span>
                  )}{" "}
                  a{" "}
                  {dataUser.esMesaCompartida === 1 ? (
                    <span style={{ fontWeight: "bold" }}>Mesa Dedicada</span>
                  ) : (
                    <span style={{ fontWeight: "bold" }}>Mesa Compartida</span>
                  )}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body1" style={{ fontWeight: "bold" }}>
                  {" "}
                  Desea continuar?
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ marginLeft: "3%", marginRight: "3%" }}>
            <div className="col-12">
              <div className="d-md-flex align-items-center">
                <div className="ms-auto mt-md-0">
                  <Button
                    onClick={handleCloseDesktopConfirmation}
                    color="primary"
                    style={{
                      textTransform: "none",
                      backgroundColor: "#eef5fe",
                      color: "black !important",
                    }}
                    className="no-bg btn rounded-pill btn-light-info px-3 MuiButton-textDark"
                  >
                    <i className="ti ti-x me-2 fs-4"></i> Cancelar
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    className="btn btn-primary font-medium rounded-pill px-4"
                    onClick={handleCambiarMesa}
                    style={{ textTransform: "none" }}
                  >
                    <div className="d-flex align-items-center">
                      <i class="ti ti-check me-2 fs-4"></i>
                      Confirmar
                    </div>
                  </Button>
                </div>
              </div>
            </div>
          </DialogActions>
        </Dialog>
        <Fab
          variant="extended"
          color="secondary"
          aria-label="  Descargar Historial de Acciones"
          style={{
            margin: 0,
            top: "auto",
            left: "auto",
            right: 200,
            bottom: 20,
            position: "fixed",
          }}
          onClick={generateExcelFile}
        >
          <Download sx={{ mr: 1 }} />
          Descargar Historial de Acciones
        </Fab>
        <Fab
          variant="extended"
          color="secondary"
          aria-label=" Abrir Historial de Acciones"
          style={{
            margin: 0,
            top: "auto",
            left: "auto",
            right: 530,
            bottom: 20,
            position: "fixed",
          }}
          onClick={() => setOpenLogs(true)}
        >
          <ListAlt sx={{ mr: 1 }} />
          Abrir Historial de Acciones
        </Fab>
      </Grid>
    </>
  );
};
