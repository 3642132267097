import {
  useGetCategoriasQuery,
  useGetEventQuery,
  useGetLocacionesQuery,
  useUpdateEventMutation,
  useGetFuentesSolicitantesQuery,
  useGetTicketUsersQuery,
  useGetUsersGLPIQuery,
  useGetBolsaHorasEntidadQuery,
  useGetResponsesQuery,
  useGetTicketLogQuery,
} from "../../features/api/apiYeapGo";

import {
  Autocomplete,
  Box,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Cancel } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import swal from "sweetalert2";
import { ComponenteBehavior } from "./ComponentBehavior";
import { useAtom } from "jotai";
import { userAtom } from "../../features/user/userAtom";
import AutocompleteSelect from "./Autocomplete";
import { DateTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import {
  Impact,
  Priority,  
  RolesEliminacion,
  Status,
  Type,
  Urgency,
} from "../../features/Types";
import ResponsesList from "./ResponsesList";

export const Event = () => {
  let { id } = useParams();
  const [data, setData] = useState({
    observadores: [],
    solicitantes: [],
    asignados: [],
  });
  const [cantidad, setCantidad] = useState(0);
  const [send, setSend] = useState(false);
  const [llenadoUsuarios, setLLenadoUsuarios] = useState(false);
  const [medida, setMedida] = useState("Horas");  
  const [openResponses, setOpenResponses] = useState(false);
  const [user] = useAtom(userAtom);
  const { Token } = user;
  const navigate = useNavigate();
  const [updateEvent, { isSuccess, isError }] = useUpdateEventMutation();

  /* Data */
  const {
    data: dataQuery,
    error,
    isLoading,
  } = useGetEventQuery({ Token, id: id });
  const { data: ticketUsers } = useGetTicketUsersQuery({
    Token,
    id: id,
  });
  const { data: Categorias } = useGetCategoriasQuery({
    Token,
    entidad: dataQuery?.Entidad,
  });
  const { data: Responses } = useGetResponsesQuery({
    Token,
    id: id,
  });
  const { data: Locaciones } = useGetLocacionesQuery({
    Token,
    entidad: dataQuery?.Entidad,
  });
  const { data: Fuentes } = useGetFuentesSolicitantesQuery({
    Token,
    entidad: dataQuery?.Entidad,
  });
  const { data: bolsaHoras } = useGetBolsaHorasEntidadQuery({
    Token,
    entidad: dataQuery?.Entidad,
  });
  const { data: ticketLogs } = useGetTicketLogQuery({
    Token,
    id: id,
  });
  const { data: UsersGLPI } = useGetUsersGLPIQuery({
    Token,
  });

  /* UseEffects */
  useEffect(() => {
    if (!user.Token) {
      navigate("login");
    } else if (!user?.modulos?.includes("Modificación de tickets")) {
      swal.fire({
        title: "Sin permisos suficientes",
        icon: "error",
        text: "No tiene los permisos suficientes para modificar tickets",
        position: "top",
        customClass: {
          confirmButton: "custom-swal-confirm-button-error",
        },
        width: "1000px",
      });
      navigate("/");
    }
  }, [user]);

  useEffect(() => {
    if (bolsaHoras && cantidad > 0) {
      let tiempoRestante = bolsaHoras[0]?.tiempo_restante;
      let cantidad_en_horas;
      if (medida === "Minutos") {
        cantidad_en_horas = cantidad / 60;
      } else if (medida === "Horas") {
        cantidad_en_horas = cantidad;
      } else if (medida === "Días") {
        cantidad_en_horas = cantidad * 24;
      } else {
        cantidad_en_horas = 0;
      }

      let resultado_en_horas = tiempoRestante - cantidad_en_horas;
      if (resultado_en_horas < 0) {
        swal.fire({
          title: "Error",
          icon: "error",
          text: `Quedan ${tiempoRestante} horas en la bolsa de Horas`,
          position: "top",
          customClass: {
            confirmButton: "custom-swal-confirm-button-error",
          },
          width: "1000px",
        });
        setCantidad(0);
        setMedida("Horas");
      } else {
        setData({
          ...data,
          Tiempo_Invertido: cantidad + " " + medida,
          cantidad,
          medida,
        });
      }
    }
  }, [cantidad, medida]);

  useEffect(() => {
    if (ticketUsers) {
      setData({
        ...data,
        observadores:
          ticketUsers
            ?.filter((user) => user.rol === "observador")
            .map((e) => e.value) || [],
        solicitantes:
          ticketUsers
            ?.filter((user) => user.rol === "solicitante")
            .map((e) => e.value) || [],
        asignados:
          ticketUsers
            ?.filter((user) => user.rol === "asignado")
            .map((e) => e.value) || [],
        observadoresIniciales:
          ticketUsers
            ?.filter((user) => user.rol === "observador")
            .map((e) => e.value) || [],
        solicitantesIniciales:
          ticketUsers
            ?.filter((user) => user.rol === "solicitante")
            .map((e) => e.value) || [],
        asignadosIniciales:
          ticketUsers
            ?.filter((user) => user.rol === "asignado")
            .map((e) => e.value) || [],
      });
      setLLenadoUsuarios(true);
    }
  }, [ticketUsers]);

  useEffect(() => {
    if (isSuccess) {
      swal.fire({
        title: "Ticket actualizado correctamente",
        icon: "success",
        position: "top",
        customClass: {
          confirmButton: "custom-swal-confirm-button-success",
        },
        width: "1000px",
      });
      navigate("/eventos");
    }
    if (isError) {
      swal.fire({
        title: "No se pudo actualizar el ticket",
        icon: "error",
        position: "top",
        customClass: {
          confirmButton: "custom-swal-confirm-button-error",
        },
        width: "1000px",
      });
    }
  }, [isSuccess, isError]);

  useEffect(() => {
    if (!isLoading && dataQuery && llenadoUsuarios) {
      setData({
        ...data,
        ...dataQuery,
        Categoria: dataQuery.Categoria > 0 ? dataQuery.Categoria : null,
        location: dataQuery.location > 0 ? dataQuery.location : null,
      });

      if (dataQuery.Tiempo_Invertido) {
        setCantidad(dataQuery.Tiempo_Invertido.split(" ")[0]);
        setMedida(dataQuery.Tiempo_Invertido.split(" ")[1]);
      }
    }
  }, [dataQuery, llenadoUsuarios]);

  useEffect(() => {
    if (error) {
      swal.fire({
        title: "No se encontró el evento",
        icon: "error",
        position: "top",
        customClass: {
          confirmButton: "custom-swal-confirm-button-error",
        },
        width: "1000px",
      });
      navigate("/eventos");
    }
  }, [error]);


  const handleAddObservador = (event) => {
    let value = event.target.lastChild.data;
    let regexUser = /\(([^)]+)\)/;    
    value = value.match(regexUser)[1];
    if (value && !data.observadores?.includes(value)) {
      setData({
        ...data,
        observadores: [...data.observadores, value],
      });
    }
  };
  const handleRemoveObservador = (option) => {
    const updatedOptions = data.observadores.filter((item) => item !== option);
    setData({
      ...data,
      observadores: updatedOptions,
    });
  };
  const handleAddSolicitante = (event) => {
    let value = event.target.lastChild.data;
    let regexUser = /\(([^)]+)\)/;    
    value = value.match(regexUser)[1];
    if (value && !data.solicitantes?.includes(value)) {
      setData({
        ...data,
        solicitantes: [...data.solicitantes, value],
      });
    }
  };
  const handleRemoveSolicitante = (option) => {
    const updatedOptions = data.solicitantes.filter((item) => item !== option);
    setData({
      ...data,
      solicitantes: updatedOptions,
    });
  };
  const handleAddAsignado = (event) => {
    let value = event.target.lastChild.data;
    let regexUser = /\(([^)]+)\)/;    
    value = value.match(regexUser)[1];
    if (value && !data.asignados?.includes(value)) {
      setData({
        ...data,
        asignados: [...data.asignados, value],
      });
    }
  };
  const handleRemoveAsignado = (option) => {
    const updatedOptions = data.asignados.filter((item) => item !== option);
    setData({
      ...data,
      asignados: updatedOptions,
    });
  };

  /* Functions */
  const gestionar = () => {
    setSend(true);
    if (
      data.Fecha &&
      data.FuenteSolicitante !== null &&
      data.FuenteSolicitante > 0 &&
      data.Impact !== null &&
      data.Impact > 0 &&
      data.Priority !== null &&
      data.Priority > 0 &&
      data.Status !== null &&
      data.Status > 0 &&
      data.Type !== null &&
      data.Type > 0 &&
      data.Urgency !== null &&
      data.Urgency > 0 &&
      data.Categoria !== null &&
      data.Categoria > 0 &&
      data.location !== null &&
      data.location > 0
    ) {
      if (dataQuery.Status === 6 && data.Status !== 6) {
        swal.fire({
          title: "El ticket se encuentra cerrado no puede cambiarlo de estado",
          icon: "error",
          position: "top",
          customClass: {
            confirmButton: "custom-swal-confirm-button-error",
          },
          width: "1000px",
        });
        return;
      }
      updateEvent({
        Token,
        id: id,
        body: {
          id: id,
          ...data,
          cantidad,
          medida,
          Tiempo_Invertido: cantidad + " " + medida,
          id_observadores: UsersGLPI.filter((u) =>
            data.observadores.includes(u.name)
          ).map((u) => u.id),
          id_solicitantes: UsersGLPI.filter((u) =>
            data.solicitantes.includes(u.name)
          ).map((u) => u.id),
          id_asignados: UsersGLPI.filter((u) =>
            data.asignados.includes(u.name)
          ).map((u) => u.id),
          id_observadoresEliminar: ticketUsers
            .filter(
              (u) =>
                data.observadoresIniciales
                  .filter((obs) => !data.observadores.includes(obs))
                  .includes(u.value) && u.rol === "observador"
            )
            .map((u) => u.id),
          id_solicitantesEliminar: ticketUsers
            .filter(
              (u) =>
                data.solicitantesIniciales
                  .filter((obs) => !data.solicitantes.includes(obs))
                  .includes(u.value) && u.rol === "solicitante"
            )
            .map((u) => u.id),
          id_asignadosEliminar: ticketUsers
            .filter(
              (u) =>
                data.asignadosIniciales
                  .filter((obs) => !data.asignados.includes(obs))
                  .includes(u.value) && u.rol === "asignado"
            )
            .map((u) => u.id),
        },
      })
        .unwrap()
        .then(() => {
          setSend(false);
          swal.fire({
            title: "Ticket actualizado correctamente",
            icon: "success",
            position: "top",
            customClass: {
              confirmButton: "custom-swal-confirm-button-success",
            },
            width: "1000px",
          });
        })
        .catch((err) => {
          swal.fire({
            title: "No se pudo actualizar el ticket",
            icon: "error",
            position: "top",
            customClass: {
              confirmButton: "custom-swal-confirm-button-error",
            },
            width: "1000px",
          });
        });
    } else {
      swal.fire({
        title: "Hay que llenar todos los campos",
        icon: "error",
        text: "Valide que todos los campos hayan sido llenados correctamente",
        position: "top",
        customClass: {
          confirmButton: "custom-swal-confirm-button-error",
        },
        width: "1000px",
      });
    }
  };

  const eliminar = ()=>{

    setSend(true);
    
      if (dataQuery.Status === 6 && data.Status !== 6) {
        swal.fire({
          title: "El ticket se encuentra cerrado no puede cambiarlo de estado",
          icon: "error",
          position: "top",
          customClass: {
            confirmButton: "custom-swal-confirm-button-error",
          },
          width: "1000px",
        });
        return;
      }
      updateEvent({
        Token,
        id: id,
        body: {
          id: id,
          is_deleted:-1,
          ...data,
          cantidad,
          medida,
          Tiempo_Invertido: cantidad + " " + medida,
          id_observadores: UsersGLPI.filter((u) =>
            data.observadores.includes(u.name)
          ).map((u) => u.id),
          id_solicitantes: UsersGLPI.filter((u) =>
            data.solicitantes.includes(u.name)
          ).map((u) => u.id),
          id_asignados: UsersGLPI.filter((u) =>
            data.asignados.includes(u.name)
          ).map((u) => u.id),
          id_observadoresEliminar: ticketUsers
            .filter(
              (u) =>
                data.observadoresIniciales
                  .filter((obs) => !data.observadores.includes(obs))
                  .includes(u.value) && u.rol === "observador"
            )
            .map((u) => u.id),
          id_solicitantesEliminar: ticketUsers
            .filter(
              (u) =>
                data.solicitantesIniciales
                  .filter((obs) => !data.solicitantes.includes(obs))
                  .includes(u.value) && u.rol === "solicitante"
            )
            .map((u) => u.id),
          id_asignadosEliminar: ticketUsers
            .filter(
              (u) =>
                data.asignadosIniciales
                  .filter((obs) => !data.asignados.includes(obs))
                  .includes(u.value) && u.rol === "asignado"
            )
            .map((u) => u.id),
        },
      })
        .unwrap()
        .then(() => {
          setSend(false);
          swal.fire({
            title: "Ticket eliminado correctamente",
            icon: "success",
            position: "top",
            customClass: {
              confirmButton: "custom-swal-confirm-button-success",
            },
            width: "1000px",
          });
        })
        .catch((err) => {
          swal.fire({
            title: "No se pudo eliminar el ticket",
            icon: "error",
            position: "top",
            customClass: {
              confirmButton: "custom-swal-confirm-button-error",
            },
            width: "1000px",
          });
        });
  
  }


  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      {isLoading || !UsersGLPI || !data ? (
        <CircularProgress color="secondary" />
      ) : (
        <Box sx={{ flexGrow: 1, ml: 1, mt: 1 }}>
          <>
            {" "}
            <div className="card shadow-none position-relative overflow-hidden">
              <div className="card-body px-4 py-3 overflow-auto">
                <div className="row align-items-center">
                  <div className="col-9">
                    <h4 className="fw-semibold mb-8">
                      Información de evento individual
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <section>
              <div className="row mb-3">
                <div className="col-12 d-flex align-items-stretch mb-3">
                  <div
                    id="modalTrazabilidad"
                    className="modal fade"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="modalTrazabilidadLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-lg">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="card-title mb-0">
                            Trazabilidad del ticket
                          </h5>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <hr className="mt-0 " />
                        <div className="modal-body">
                          <form className="">
                            <div className="row">
                              <div className="col-md-12">
                                {ticketLogs ? (
                                  <>
                                    {ticketLogs.map((cadena, index) => (
                                      <div
                                        key={index}
                                        className="form-group row"
                                      >
                                        <p className="form-control-static">
                                          <span>{cadena}</span>
                                        </p>
                                      </div>
                                    ))}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>

                  <button
                    type="button"
                    className="btn btn-primary p-3 w-100 d-flex align-items-center justify-content-center "
                    data-bs-toggle="modal"
                    data-bs-target="#modalTrazabilidad"
                    fdprocessedid="tml8fm"
                  >
                    <i
                      className="ti ti-calendar-time fs-7"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      aria-label="Abrir trazabilidad de ticket"
                    ></i>
                    Abrir trazabilidad de ticket
                  </button>
                </div>

                <div className="col-12 d-flex align-items-stretch">
                  <div className="card w-100">
                    <div className="card-body overflow-auto">
                      <div className="col-md-12">
                        <div className="col-lg-6 d-flex align-items-stretch  float-l">
                          <div className="me-2 w-100">
                            <div className="accordion" id="accordionDetails">
                              <div className="accordion-item">
                                <h2
                                  className="accordion-header"
                                  id="headingOne"
                                >
                                  <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne"
                                    aria-expanded="false"
                                    aria-controls="collapseOne"
                                  >
                                    Detalles del evento
                                  </button>
                                </h2>
                                <div
                                  id="collapseOne"
                                  className="accordion-collapse collapse"
                                  aria-labelledby="headingOne"
                                  data-bs-parent="#accordionDetails"
                                >
                                  <div className="accordion-body">
                                    <fieldset disabled="disabled">
                                      <form>
                                        <div className="row">
                                          <div className="col-md-3">
                                            <div className="form-floating mb-3">
                                              <input
                                                type="text"
                                                className="form-control"
                                                id="tb-ftitle"
                                                placeholder="Ingrese el título del ticket"
                                                value={data.Id_Ticket || ""}
                                              />
                                              <label for="tb-fname">ID</label>
                                            </div>
                                          </div>
                                          <div className="col-md-9">
                                            <div className="form-floating mb-3">
                                              <input
                                                type="text"
                                                className="form-control"
                                                id="tb-ftitle"
                                                placeholder="Ingrese el título del ticket"
                                                value={data.TicketName || ""}
                                              />
                                              <label for="tb-fname">
                                                Título
                                              </label>
                                            </div>
                                          </div>
                                          <div className="col-md-6">
                                            <div className="form-floating mb-3">
                                              <label className="control-labe fs-2 mt-n2 mx-n2">
                                                Fecha de apertura
                                              </label>
                                              <input
                                                type="text"
                                                className="form-control"
                                                value={data.Fecha || ""}
                                              />
                                            </div>
                                          </div>
                                          <div className="col-md-6">
                                            <div className="form-floating mb-3">
                                              <label className="control-labe fs-2 mt-n2 mx-n2">
                                                Fecha de actualización
                                              </label>
                                              <input
                                                type="text"
                                                className="form-control"
                                                value={data.Date_mod || ""}
                                              />
                                            </div>
                                          </div>
                                          {Fuentes &&
                                          dataQuery?.FuenteSolicitante ? (
                                            <div className="col-md-6">
                                              <div className="form-floating mb-3">
                                                <label className="control-labe fs-2 mt-n2 mx-n2">
                                                  Fuente solicitante
                                                </label>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  value={
                                                    Fuentes.filter(
                                                      (f) =>
                                                        f.id ===
                                                        dataQuery.FuenteSolicitante
                                                    )[0]?.name ||
                                                    dataQuery.FuenteSolicitante
                                                  }
                                                />
                                              </div>
                                            </div>
                                          ) : (
                                            <></>
                                          )}
                                          {Locaciones && dataQuery?.location ? (
                                            <div className="col-md-6">
                                              <div className="form-floating mb-3">
                                                <label className="control-labe fs-2 mt-n2 mx-n2">
                                                  Locación
                                                </label>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  value={
                                                    Locaciones.filter(
                                                      (f) =>
                                                        f.id ===
                                                        dataQuery.location
                                                    )[0]?.completename ||
                                                    dataQuery.location
                                                  }
                                                />
                                              </div>
                                            </div>
                                          ) : (
                                            <></>
                                          )}
                                          <div className="col-md-12">
                                            <div className="form-floating mb-3">
                                              <label className="control-labe fs-2 mt-n2 mx-n2">
                                                Entidad
                                              </label>
                                              <input
                                                type="text"
                                                className="form-control"
                                                value={data.NombreEntidad || ""}
                                              />
                                            </div>
                                          </div>{" "}
                                          {ticketUsers?.filter(
                                            (user) => user.rol === "solicitante"
                                          ).length > 0 ? (
                                            <div className="col-md-6">
                                              <div className="form-floating mb-3">
                                                <label className="control-labe fs-2 mt-n2 mx-n2">
                                                  Solicitante
                                                </label>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  value={
                                                    ticketUsers
                                                      ?.filter(
                                                        (user) =>
                                                          user.rol ===
                                                          "solicitante"
                                                      )
                                                      .map((e) => e.value)
                                                      .join(", ") || " "
                                                  }
                                                />
                                              </div>
                                            </div>
                                          ) : (
                                            <></>
                                          )}
                                          {ticketUsers?.filter(
                                            (user) => user.rol === "observador"
                                          ).length > 0 ? (
                                            <div className="col-md-6">
                                              <div className="form-floating mb-3">
                                                <label className="control-labe fs-2 mt-n2 mx-n2">
                                                  Observador
                                                </label>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  value={
                                                    ticketUsers
                                                      ?.filter(
                                                        (user) =>
                                                          user.rol ===
                                                          "observador"
                                                      )
                                                      .map((e) => e.value)
                                                      .join(", ") || " "
                                                  }
                                                />
                                              </div>
                                            </div>
                                          ) : (
                                            <></>
                                          )}
                                          <div className="col-md-12">
                                            <div className="form-floating mb-3">
                                              <label className="control-labe fs-2 mt-n2 mx-n2">
                                                Contenido
                                              </label>
                                              <div
                                                className="form-control"   style={{
                                                  maxWidth: '33vw',  
                                                  overflow: 'auto',  
                                                }}
                                                dangerouslySetInnerHTML={{
                                                  __html: data.Content,
                                                }}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </form>
                                    </fieldset>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 d-flex align-items-stretch  float-l">
                          <div className="me-2 w-100">
                            <div className="accordion" id="accordionReq">
                              <div className="accordion-item">
                                <h2
                                  className="accordion-header"
                                  id="headingTwo"
                                >
                                  <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo"
                                    aria-expanded="false"
                                    aria-controls="collapseTwo"
                                  >
                                    Campos requeridos
                                  </button>
                                </h2>
                                <div
                                  id="collapseTwo"
                                  className="accordion-collapse collapse"
                                  aria-labelledby="headingTwo"
                                  data-bs-parent="#accordionReq"
                                >
                                  <div className="accordion-body">
                                    <form>
                                      <div className="row">
                                        <div className="col-md-6">
                                          <div className="form-floating mb-3">
                                            <DateTimePicker
                                              label="Fecha de apertura"
                                              value={dayjs(data.Fecha)}
                                              onChange={(newValue) =>
                                                setData({
                                                  ...data,
                                                  Fecha: dayjs(newValue).format(
                                                    "YYYY-MM-DD HH:mm:ss"
                                                  ),
                                                })
                                              }
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="form-floating mb-3">
                                            <Grid item xs={12}>
                                              <AutocompleteSelect
                                                options={Status}
                                                data={data}
                                                setData={setData}
                                                propiedad={"Status"}
                                                label="Estado"
                                              />
                                            </Grid>{" "}
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="form-floating mb-3">
                                            <Grid item xs={12}>
                                              <AutocompleteSelect
                                                options={Urgency}
                                                label="Urgencia"
                                                data={data}
                                                setData={setData}
                                                propiedad={"Urgency"}
                                              />
                                            </Grid>{" "}
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="form-floating mb-3">
                                            <Grid item xs={12}>
                                              <AutocompleteSelect
                                                options={Impact}
                                                label="Impacto"
                                                data={data}
                                                setData={setData}
                                                propiedad={"Impact"}
                                              />
                                            </Grid>{" "}
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="form-floating mb-3">
                                            <Grid item xs={12}>
                                              <AutocompleteSelect
                                                options={Type}
                                                label="Tipo"
                                                data={data}
                                                setData={setData}
                                                propiedad={"Type"}
                                              />
                                            </Grid>{" "}
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="form-floating mb-3">
                                            <Grid item xs={12}>
                                              <AutocompleteSelect
                                                options={Priority}
                                                label="Prioridad"
                                                data={data}
                                                setData={setData}
                                                propiedad={"Priority"}
                                              />
                                            </Grid>{" "}
                                          </div>
                                        </div>
                                        <div className="col-md-12">
                                          <div className="form-floating mb-3">
                                            <Grid item xs={12}>
                                              {Categorias ? (
                                                <AutocompleteSelect
                                                  options={Categorias || []}
                                                  label="Categoría *"
                                                  data={data}
                                                  setData={setData}
                                                  propiedad={"Categoria"}
                                                  requerido={send}
                                                />
                                              ) : (
                                                <></>
                                              )}
                                            </Grid>{" "}
                                          </div>
                                        </div>
                                        <div className="col-md-12">
                                          <div className="form-floating mb-3">
                                            <Grid item xs={12}>
                                              {Locaciones &&
                                              !dataQuery?.location ? (
                                                <AutocompleteSelect
                                                  options={Locaciones || []}
                                                  label="Locación *"
                                                  data={data}
                                                  setData={setData}
                                                  propiedad={"location"}
                                                  requerido={send}
                                                />
                                              ) : (
                                                <></>
                                              )}
                                            </Grid>{" "}
                                          </div>
                                        </div>
                                        <div className="col-md-12">
                                          <div className="form-floating mb-3">
                                            {llenadoUsuarios &&
                                            UsersGLPI &&
                                            ticketUsers ? (
                                              <>
                                                <div className="col-md-12">
                                                  <div className="form-floating mb-3">
                                                    <Grid item xs={12}>
                                                      <Autocomplete
                                                        multiple
                                                        options={
                                                          UsersGLPI
                                                            .filter(user => user.name)
                                                            .map(user => (`${user.firstname ?? ""} ${user.realname ?? ""} (${user.name})`))
                                                            .sort((a, b) => a.localeCompare(b)) || []
                                                        }
                                                        value={
                                                          data.solicitantes
                                                        }
                                                        onChange={
                                                          handleAddSolicitante
                                                        }
                                                        renderInput={(
                                                          params
                                                        ) => (
                                                          <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            label="solicitantes"
                                                            placeholder="solicitantes"
                                                            onKeyDown={(
                                                              event
                                                            ) => {
                                                              if (
                                                                event.key ===
                                                                "Enter"
                                                              ) {
                                                                handleAddSolicitante(
                                                                  event
                                                                );
                                                                event.preventDefault();
                                                              }
                                                            }}
                                                          />
                                                        )}
                                                        renderTags={(value) =>
                                                          value.map(
                                                            (option) => (
                                                              <div
                                                                style={{
                                                                  display:
                                                                    "flex",
                                                                  alignItems:
                                                                    "center",
                                                                  borderColor:
                                                                    "#e5007c",
                                                                  backgroundColor:
                                                                    "#FFB8DE",
                                                                  marginRight:
                                                                    "2%",
                                                                  padding: "1%",
                                                                  borderRadius:
                                                                    "10%",
                                                                }}
                                                                key={option}
                                                              >
                                                                {option + "  "}
                                                                <button
                                                                  style={{
                                                                    background:
                                                                      "none",
                                                                    border:
                                                                      "none",
                                                                  }}
                                                                  onClick={() =>
                                                                    handleRemoveSolicitante(
                                                                      option
                                                                    )
                                                                  }
                                                                >
                                                                  <Cancel
                                                                    style={{
                                                                      fontSize:
                                                                        "20px",
                                                                    }}
                                                                  />
                                                                </button>
                                                              </div>
                                                            )
                                                          )
                                                        }
                                                      />
                                                    </Grid>
                                                  </div>
                                                </div>
                                                <div className="col-md-12">
                                                  <div className="form-floating mb-3">
                                                    <Grid item xs={12}>
                                                      <Autocomplete
                                                        multiple
                                                        options={
                                                          UsersGLPI
                                                            .filter(user => user.name)
                                                            .map(user => (`${user.firstname ?? ""} ${user.realname ?? ""} (${user.name})`))
                                                            .sort((a, b) => a.localeCompare(b)) || []
                                                        }
                                                        value={
                                                          data.observadores
                                                        }
                                                        onChange={
                                                          handleAddObservador
                                                        }
                                                        renderInput={(
                                                          params
                                                        ) => (
                                                          <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            label="observadores"
                                                            placeholder="observadores"
                                                            onKeyDown={(
                                                              event
                                                            ) => {
                                                              if (
                                                                event.key ===
                                                                "Enter"
                                                              ) {
                                                                handleAddObservador(
                                                                  event
                                                                );
                                                                event.preventDefault();
                                                              }
                                                            }}
                                                          />
                                                        )}
                                                        renderTags={(value) =>
                                                          value.map(
                                                            (option) => (
                                                              <div
                                                                style={{
                                                                  display:
                                                                    "flex",
                                                                  alignItems:
                                                                    "center",
                                                                  borderColor:
                                                                    "#e5007c",
                                                                  backgroundColor:
                                                                    "#FFB8DE",
                                                                  marginRight:
                                                                    "2%",
                                                                  padding: "1%",
                                                                  borderRadius:
                                                                    "10%",
                                                                }}
                                                                key={option}
                                                              >
                                                                {option + "  "}
                                                                <button
                                                                  style={{
                                                                    background:
                                                                      "none",
                                                                    border:
                                                                      "none",
                                                                  }}
                                                                  onClick={() =>
                                                                    handleRemoveObservador(
                                                                      option
                                                                    )
                                                                  }
                                                                >
                                                                  <Cancel
                                                                    style={{
                                                                      fontSize:
                                                                        "20px",
                                                                    }}
                                                                  />
                                                                </button>
                                                              </div>
                                                            )
                                                          )
                                                        }
                                                      />
                                                    </Grid>
                                                  </div>
                                                </div>
                                                <div className="col-md-12">
                                                  <div className="form-floating mb-3">
                                                    <Grid item xs={12}>
                                                      <Autocomplete
                                                        multiple
                                                        options={
                                                          UsersGLPI
                                                            .filter(user => user.name)
                                                            .map(user => (`${user.firstname ?? ""} ${user.realname ?? ""} (${user.name})`))
                                                            .sort((a, b) => a.localeCompare(b)) || []
                                                        }
                                                        value={data.asignados}
                                                        onChange={
                                                          handleAddAsignado
                                                        }
                                                        renderInput={(
                                                          params
                                                        ) => (
                                                          <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            label="asignados"
                                                            placeholder="asignados"
                                                            onKeyDown={(
                                                              event
                                                            ) => {
                                                              if (
                                                                event.key ===
                                                                "Enter"
                                                              ) {
                                                                handleAddAsignado(
                                                                  event
                                                                );
                                                                event.preventDefault();
                                                              }
                                                            }}
                                                          />
                                                        )}
                                                        renderTags={(value) =>
                                                          value.map(
                                                            (option) => (
                                                              <div
                                                                style={{
                                                                  display:
                                                                    "flex",
                                                                  alignItems:
                                                                    "center",
                                                                  borderColor:
                                                                    "#e5007c",
                                                                  backgroundColor:
                                                                    "#FFB8DE",
                                                                  marginRight:
                                                                    "2%",
                                                                  padding: "1%",
                                                                  borderRadius:
                                                                    "10%",
                                                                }}
                                                                key={option}
                                                              >
                                                                {option + "  "}
                                                                <button
                                                                  style={{
                                                                    background:
                                                                      "none",
                                                                    border:
                                                                      "none",
                                                                  }}
                                                                  onClick={() =>
                                                                    handleRemoveAsignado(
                                                                      option
                                                                    )
                                                                  }
                                                                >
                                                                  <Cancel
                                                                    style={{
                                                                      fontSize:
                                                                        "20px",
                                                                    }}
                                                                  />
                                                                </button>
                                                              </div>
                                                            )
                                                          )
                                                        }
                                                      />
                                                    </Grid>
                                                  </div>
                                                </div>
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                          </div>
                                        </div>
                                        <div className="col-md-12">
                                          <div className="form-floating mb-3">
                                            <Grid item xs={12}>
                                              {Fuentes &&
                                              !dataQuery?.FuenteSolicitante ? (
                                                <AutocompleteSelect
                                                  options={
                                                    Fuentes?.map((f) => {
                                                      return {
                                                        ...f,
                                                        completename: f.name,
                                                      };
                                                    }) || []
                                                  }
                                                  label="Fuente solicitante "
                                                  data={data}
                                                  setData={setData}
                                                  propiedad={
                                                    "FuenteSolicitante"
                                                  }
                                                />
                                              ) : (
                                                <></>
                                              )}
                                            </Grid>{" "}
                                          </div>
                                        </div>
                                        <div className="col-md-12">
                                          <div className="form-floating mb-3">
                                            {data.Time_to_resolve ? (
                                              <Grid item xs={12} md={12}>
                                                <TextField
                                                  size="small"
                                                  disabled
                                                  fullWidth
                                                  label="Tiempo para resolver (TTR)"
                                                  value={`${
                                                    data.Time_to_resolve
                                                  } - ${
                                                    Priority[data.Priority - 1]
                                                      .completename
                                                  }`}
                                                  focused
                                                  color="secondary"
                                                />
                                              </Grid>
                                            ) : (
                                              <></>
                                            )}{" "}
                                          </div>
                                        </div>
                                        <div className="col-md-12">
                                          <div className="form-floating mb-3">
                                            <Grid item xs={12}>
                                              <InputLabel>
                                                Tiempo invertido
                                              </InputLabel>
                                              <div>
                                                <TextField
                                                  label="Cantidad"
                                                  type="number"
                                                  value={cantidad}
                                                  onChange={(e) => {
                                                    if (
                                                      parseInt(
                                                        e.target.value
                                                      ) >= 0 ||
                                                      e.target.value === ""
                                                    ) {
                                                      setCantidad(
                                                        e.target.value
                                                      );
                                                    }
                                                  }}
                                                />
                                                <FormControl
                                                  style={{ width: "7vw" }}
                                                >
                                                  <InputLabel id="medida-label">
                                                    Medida
                                                  </InputLabel>
                                                  <Select
                                                    labelId="medida-label"
                                                    value={medida}
                                                    onChange={(e) => {
                                                      setMedida(e.target.value);
                                                    }}
                                                  >
                                                    <MenuItem value="Minutos">
                                                      Minutos
                                                    </MenuItem>
                                                    <MenuItem value="Horas">
                                                      Horas
                                                    </MenuItem>
                                                    <MenuItem value="Días">
                                                      Días
                                                    </MenuItem>
                                                  </Select>
                                                </FormControl>
                                              </div>
                                            </Grid>
                                          </div>
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="col-lg-12 d-flex align-items-stretch  float-l">
                          <div className="me-2 w-100">
                            <div className="accordion" id="accordionStat">
                              <div className="accordion-item">
                                <h2
                                  className="accordion-header"
                                  id="headingThree"
                                >
                                  <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree"
                                    aria-expanded="false"
                                    aria-controls="collapseThree"
                                  >
                                    Actividad del ticket
                                  </button>
                                </h2>
                                <div
                                  id="collapseThree"
                                  className="accordion-collapse collapse"
                                  aria-labelledby="headingThree"
                                  data-bs-parent="#accordionStat"
                                >
                                  <div className="accordion-body">
                                    <ComponenteBehavior
                                      idTicket={id || ""}
                                      nombreEntidad={data.NombreEntidad || ""}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row"></div>
            </section>
          </>
          <div class="d-flex justify-content-end">
          
            <button
              type="button"
              class="btn btn-primary p-3 z-index-5 rounded-pill d-flex align-items-center justify-content-center corner-btn manage-btn"
              data-bs-toggle="modal"
              data-bs-target="#myModal"
              fdprocessedid="tml8fm"
              onClick={gestionar}
            >
              <i
                class="ti ti-tool fs-7"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Gestión masiva"
              ></i>
              Guardar
            </button>
            <button
              type="button"
              class="btn btn-primary p-3 z-index-5 rounded-pill d-flex align-items-center justify-content-center corner-btn back-btn"
              data-bs-toggle="modal"
              data-bs-target="#myModal"
              fdprocessedid="tml8fm"
              onClick={() => {
                navigate("/eventos");
              }}
            >
              <i
                class="ti ti-arrow-back pe-1 fs-7"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Volver a la página anterior"
              ></i>
              Volver
            </button>

            <button
              type="button"
              class="btn btn-primary p-3 z-index-5 rounded-pill d-flex align-items-center justify-content-center corner-btn answers-btn"
              data-bs-toggle="modal"
              data-bs-target="#modalAnswers"
              fdprocessedid="tml8fm"
            >
              <i
                class="ti ti-message-2 pe-1 fs-7"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Respuestas al ticket"
              ></i>
              Respuestas
            </button>
            {
              RolesEliminacion.map((rol) => rol.completename).includes(
                user?.Rol
              )?
            <button
              type="button"
              class="btn btn-primary p-3 z-index-5 rounded-pill d-flex align-items-center justify-content-center corner-btn manage-btn"
              data-bs-toggle="modal"
              data-bs-target="#myModal"
              fdprocessedid="tml8fm"
              onClick={()=>{
                swal.fire({
                  title: '¿Estás seguro de eliminar este ticket?',
                  text: ' ',                
                  icon: 'warning',
                  showCancelButton: true,
                  customClass: {
                    confirmButton: "custom-swal-confirm-buttontext-error",
                    cancelButton: "custom-swal-confirm-buttontext-cancel",
                  },
                  confirmButtonText: 'Sí',
                  cancelButtonText: 'Cancelar'
                }).then((result) => {
                  if (result.isConfirmed) {                    
                    eliminar();
                  }
                });
              }}
            >
              <i
                class="ti ti-trash fs-7"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Eliminar"
              ></i>
              Eliminar
            </button>
            :<></>
            }
          </div>
          <div
            id="modalAnswers"
            class="modal fade"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="modalAnswersLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-lg">
              <div class="modal-content">
                <div class="modal-header pb-0">
                  <h4 class="modal-title " id="modalAnswersLabel">
                    Respuestas
                  </h4>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body pt-0">
                  <ResponsesList
                    id={id}
                    responses={Responses}
                    open={openResponses}
                    setOpen={setOpenResponses}
                  />
                </div>
              </div>
            </div>
          </div>
        </Box>
      )}
    </Grid>
  );
};
