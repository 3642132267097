import { graphConfig } from "./authConfig";

/**
 * Attaches a given access token to a MS Graph API call. Returns information about the user
 * @param accessToken
 */
export async function callMsGraph(accessToken) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers,
  };

  const queryParameters =
    "$select=displayName,jobTitle,department,mobilePhone,userPrincipalName,givenName,surname,mail,id";
  return fetch(`${graphConfig.graphMeEndpoint}?${queryParameters}`, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
}

/**
 * Attaches a given access token to a MS Graph API call. Returns a list of users in the directory
 * @param accessToken
 */
export async function listUsers(accessToken) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers,
  };

  const queryParameters =
    "$select=displayName,jobTitle,department,mobilePhone,userPrincipalName,givenName,surname,mail"; // Agrega los campos adicionales que necesitas

  return fetch(`${graphConfig.graphUsersEndpoint}?${queryParameters}`, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
}
