/* eslint-disable react-hooks/exhaustive-deps */
import {
  useGetCategoriasQuery,
  useGetEventsQuery,
  useGetFuentesSolicitantesQuery,
  useGetRolesModuloQuery,
} from "../../features/api/apiYeapGo";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { CircularProgress, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import swal from "sweetalert2";
import { userAtom } from "../../features/user/userAtom";
import { useAtom } from "jotai";
import {
  Priority,
  RolesAdministrador,
  Status,
  Type,
} from "../../features/Types";
import AutocompleteSelect from "./Autocomplete";
import { CreateEvent } from "./CreateEvent";

export const Events = () => {
  const [user, setUser] = useAtom(userAtom);
  const { Token, entidad } = user;
  const Auth = user.Token;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [eventsID, setEventsID] = useState([]);
  const [filter, setFilter] = useState("");
  const navigate = useNavigate();
  const [dataBusqueda, setDataBusqueda] = useState({});
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [data, setData] = useState([]);
  const [rowCount, setRowCount] = useState(0);

  
  /* Data */
  const { data: Categorias } = useGetCategoriasQuery({
    Token,
    entidad: entidad,
  });
  const { data: rolesModulo } = useGetRolesModuloQuery({ Token: Auth });

  const { data: Fuentes } = useGetFuentesSolicitantesQuery({
    Token,
    entidad: entidad,
  });

  const {
    data: dataEvents,
    error,
    isLoading,
    isFetching,
    refetch,
  } = useGetEventsQuery(
    { Auth, body: { ...dataBusqueda, page: page + 1, pageSize } },
    {
      pollingInterval: 1000 * 20,
    }
  );
  useEffect(() => {
    if (dataEvents?.rows) {
      setData(dataEvents.rows);
      setRowCount(dataEvents.rowCount || 0);
    }
  }, [dataEvents]);

  // Descomponer data para obtener rows y rowCount

  const [dataQ, setDataQ] = useState({
    Id_Ticket: "",
    Status: "",
    TicketName: "",
    nameAuto: "",
    TicketAuto: "",
    statusAuto: "",
  });
  // console.log(dataQ);
  const [eventsEntidad, setEventsEntidad] = useState([]);
  const buscar = () => {
    setDataBusqueda({
      busqueda: true,
      status: dataQ.Status ?? "",
      id: dataQ.TicketAuto ?? "",
      title: dataQ.nameAuto ?? "",
    });
    refetch();
  };
  /* UseEffect */

  useEffect(() => {
    if (!user.Token) {
      navigate("login");
    } else if (!user?.modulos?.includes("Visualización de tickets")) {
      if (user?.modulos?.length > 0) {
        swal.fire({
          title: "Sin permisos suficientes",
          icon: "error",
          text: "No tiene los permisos suficientes para visualizar los tickets",
          position: "top",
          customClass: {
            confirmButton: "custom-swal-confirm-button-error",
          },
          width: "1000px",
        });
        navigate("/tem");
      } else {
        let modulos = rolesModulo
          ?.filter((r) => r.rol === user.Rol)
          .map((r) => r.modulo);
        if (modulos?.length > 0) {
          setUser({
            ...user,
            modulos: modulos,
          });
          navigate("/tem");
        } else {
          swal.fire({
            title: "No tiene acceso a ningún módulo",
            icon: "error",
            text: "Comuníquese con un administrador",
            position: "top",
            customClass: {
              confirmButton: "custom-swal-confirm-button-error",
            },
            width: "1000px",
          })
          navigate("/TkIbDCaknTtyZb")

        }
      }
    }
  }, [user]);
  useEffect(() => {
    if (error?.originalStatus === 401) {
      swal.fire({
        title: "Sesión expirada",
        icon: "warning",
        text: "Por favor vuelve a iniciar sesión",
        position: "top",
        customClass: {
          confirmButton: "custom-swal-confirm-button-warning",
        },
        width: "1000px",
      });
      setUser({});
    }
  }, [error]);
  useEffect(() => {
    setEventsEntidad(
      data
        ?.filter((ev) => {
          return (
            (parseInt(ev.Entidad) === user?.entidad ||
              user?.esMesaCompartida ||
              RolesAdministrador.map((rol) => rol.completename).includes(
                user?.Rol
              )) &&
            (filter === "" ? true : "" + ev.Priority + "" === filter)
          );
        })
        .map((eve) => {
          return {
            ...eve,
            statusName: Status.filter((e) => e.id === eve.Status)[0]
              .completename,
          };
        })
    );
  }, [data, filter]);

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangePageSize = (newPageSize) => {
    setPageSize(newPageSize);
    setPage(0); // Resetear a la primera página al cambiar el tamaño de la página
  };

  const columns = [
    {
      field: "Id_Ticket",
      headerName: "ID",
      width: 50,
      renderCell: (params) => (
        <div style={{ color: "#cd0573" }}>{params.value}</div>
      ),
    },
    {
      field: "TicketName",
      headerName: "Título",
      width: 140,
    },
    {
      field: "Content",
      headerName: "Descripción",
      width: 250,
      renderCell: (params) => (
        <div style={{ maxHeight: "200px", overflow: "auto" }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "Status",
      headerName: "Estado",
      width: 105,
      renderCell: (params) => {
        const match = Status.find((s) => s.id == params.row.Status);
        if (match) {
          return match.completename;
        } else {
          return "";
        }
      },
    },
    {
      field: "Date_mod",
      headerName: "Actualizado",
      width: 120,
      headerClassName: "multi-line-header",
    },
    {
      field: "Fecha",
      headerName: "Apertura",
      width: 120,
      headerClassName: "multi-line-header",
    },
    {
      field: "Priority",
      headerName: "Prioridad",
      width: 90,
      renderCell: (params) => {
        const match = Priority.find((s) => s.id == params.row.Priority);
        if (match) {
          return match.completename;
        } else {
          return "";
        }
      },
    },
    {
      field: "usuarioAsignado",
      headerName: "Asignado a",
      width: 110,
    },
    {
      field: "FuenteSolicitante",
      headerName: "Fuente Solicitante",
      width: 100,
      renderCell: (params) => {
        const match = Fuentes?.find(
          (s) => s.id == params.row.FuenteSolicitante
        );
        if (match) {
          return match.name;
        } else {
          return "";
        }
      },
    },
    {
      field: "Type",
      headerName: "Tipo",
      width: 120,
      renderCell: (params) => {
        const match = Type.find((s) => s.id == params.row.Type);
        if (match) {
          return match.completename;
        } else {
          return "";
        }
      },
    },
    {
      field: "Categoria",
      headerName: "Categoría",
      width: 150,
      renderCell: (params) => {
        const match = Categorias?.find((s) => s.id == params.row.Categoria);
        if (match) {
          return match.completename;
        } else {
          return "";
        }
      },
    },

    {
      field: "NombreEntidad",
      headerName: "Entidad",
      width: 100,
    },

    {
      field: "Tiempo_Invertido",
      headerName: "Tiempo invertido",
      width: 150,
      hide: true,
    },
    {
      field: "Closedate",
      headerName: "Fecha de cierre",
      width: 150,
      hide: true,
    },
    {
      field: "Solvedate",
      headerName: "Fecha de solución",
      width: 150,
      hide: true,
    },
    {
      field: "Takeintoaccountdate",
      headerName: "Fecha de toma en cuenta",
      width: 180,
      hide: true,
    },

    {
      field: "Urgency",
      headerName: "Urgencia",
      width: 150,
      hide: true,
    },
    {
      field: "Impact",
      headerName: "Impacto",
      width: 150,
      hide: true,
    },

    {
      field: "Time_to_resolve",
      headerName: "Tiempo para resolver",
      width: 180,
      hide: true,
    },
    {
      field: "Waiting_duration",
      headerName: "Duración de espera",
      width: 180,
      hide: true,
    },
    {
      field: "Is_deleted",
      headerName: "Eliminado",
      width: 150,
      hide: true,
    },
    {
      field: "location",
      headerName: "Ubicación",
      width: 150,
      hide: true,
    },
    {
      field: "tiempo_descontado",
      headerName: "Tiempo descontado",
      width: 180,
      hide: true,
    },
  ];

  
  useEffect(() => {
    if (eventsID.length === 1) {
    }
    if (eventsID.length === 0) {
    }
  }, [eventsID]);

  useEffect(() => {
    setPage(0);
    buscar();
  }, [dataQ.Status]);

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <div className="card shadow-none position-relative overflow-hidden mt-3 mb-2">
        <div className="card-body p-1 overflow-auto ">
          <h4 className="fw-semibold mb-1">Interfaz Unificada de Eventos</h4>
        </div>
      </div>
      {isLoading || !eventsEntidad ? (
        <CircularProgress color="secondary" />
      ) : (
        <>
          {" "}
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <AutocompleteSelect
                options={Status}
                data={dataQ}
                setData={setDataQ}
                propiedad={"Status"}
                fullValue={"statusAuto"}
                label="Estado"
              />
            </Grid>
            <Grid item xs={3}>
              <AutocompleteSelect
                options={
                  eventsEntidad?.map((d) => {
                    return {
                      id: d.Id_Ticket,
                      completename: d.Id_Ticket,
                    };
                  }) ?? []
                }
                data={dataQ}
                setData={setDataQ}
                propiedad={"Id_Ticket"}
                fullValue={"TicketAuto"}
                label="ID"
                solo
                inputType="number"
              />
            </Grid>

            <Grid item xs={4}>
              <AutocompleteSelect
                options={
                  eventsEntidad?.map((d) => {
                    return {
                      id: d.Id_Ticket,
                      completename: d.TicketName,
                    };
                  }) ?? []
                }
                data={dataQ}
                setData={setDataQ}
                solo
                propiedad={"TicketName"}
                fullValue={"nameAuto"}
                label="Título"
              />
            </Grid>
            <Grid item xs={2}>
              <button
                type="button"
                class="btn btn-primary p-3 w-100 d-flex align-items-center justify-content-center custom-btn-font"
                onClick={() => {
                  if (!dataQ.Status && !dataQ.TicketAuto && !dataQ.nameAuto) {
                    swal.fire({
                      title:
                        "Debe aplicar algún filtro para realizar la busqueda",
                      icon: "warning",
                      text: " ",
                      position: "top",
                      customClass: {
                        confirmButton: "custom-swal-confirm-button-warning",
                      },
                      width: "1000px",
                    });
                  } else {
                    setPage(0);
                    buscar();
                  }
                }}
              >
                <i
                  class="ti ti-search fs-7"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                ></i>
                Buscar
              </button>
            </Grid>
            <Grid item xs={3}>
              <h4>
                {Priority.map((p) => {
                  if (p.id == filter) {
                    return "Prioridad: " + p.completename;
                  }
                  return "";
                })}
              </h4>
            </Grid>
          </Grid>
          {isFetching ? (
            <Grid
              container
              spacing={2}
              justifyContent="center" // Centra horizontalmente en el contenedor
              alignItems="center" // Centra verticalmente en el contenedor
            >
              <CircularProgress color="secondary" />
            </Grid>
          ) : (
            <DataGrid
              sx={{ mb: "4%" }}
              localeText={{
                noRowsLabel: "No se ha encontrado datos.",
                noResultsOverlayLabel: "No se ha encontrado ningún resultado",
                toolbarColumns: "Columnas",
                toolbarColumnsLabel: "Seleccionar columnas",
                toolbarDensity: "Densidad",
                toolbarDensityComfortable: "Comodo",
                toolbarDensityStandard: "Estandar",
                toolbarDensityCompact: "Compacto",
                toolbarFilters: "Filtros",
                toolbarFiltersLabel: "Ver filtros",
                toolbarFiltersTooltipHide: "Quitar filtros",
                toolbarFiltersTooltipShow: "Ver filtros",
              }}
              columns={columns}
              getRowClassName={(params) => `row-color`}
              autoHeight
              getRowHeight={() => "auto"}
              rows={
                eventsEntidad?.filter((ev) => {
                  return (
                    (dataQ.Id_Ticket > 0
                      ? ("" + ev.Id_Ticket + "")?.includes(dataQ.Id_Ticket)
                      : ("" + ev.Id_Ticket + "")?.includes(dataQ.TicketAuto)) &&
                    (dataQ.Status > 0
                      ? ev.Status === dataQ.Status
                      : ("" + ev.statusName + "")
                        ?.toLocaleLowerCase()
                        .includes(dataQ.statusAuto?.toLocaleLowerCase())) &&
                    (dataQ.TicketName > 0
                      ? ev.Id_Ticket === dataQ.TicketName
                      : ("" + ev.name + "")
                        ?.toLocaleLowerCase()
                        .includes(dataQ.nameAuto?.toLocaleLowerCase()))
                  );
                }) || []
              }
              pagination
              page={page}
              pageSize={pageSize}
              onPageChange={(newPage) => handleChangePage(newPage)}
              onPageSizeChange={(newPageSize) =>
                handleChangePageSize(newPageSize)
              }
              rowCount={rowCount}
              rowsPerPageOptions={[5, 10, 15]}
              paginationMode="server"
              disableSelectionOnClick
              onRowClick={(e) => {
                user?.modulos?.includes("Modificación de tickets") &&
                  navigate(`${e.id}`);
              }}
              components={{
                Toolbar: () => {
                  return (
                    <GridToolbarContainer>
                      <GridToolbarColumnsButton />
                      <GridToolbarFilterButton />
                      <GridToolbarDensitySelector />
                    </GridToolbarContainer>
                  );
                },
              }}
              getRowId={(row) => row.Id_Ticket}
              onSelectionModelChange={(e) => setEventsID(e)}
            />
          )}         
        </>
      )}

      <div
        id="modalNewTicket"
        className="modal fade"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="modalNewTicketLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="modalNewTicketLabel">
                Crear nuevo Ticket
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <CreateEvent />
            </div>
          </div>
        </div>
      </div>
      {user?.modulos?.includes("Creación de tickets") && (
        <button
          type="button"
          className="btn btn-primary p-3 z-index-5 rounded-circle d-flex align-items-center justify-content-center newTicket-btn"
          data-bs-toggle="modal"
          data-bs-target="#modalNewTicket"
          fdprocessedid="tml8fm"
        >
          <i
            className="ti ti-calendar-plus fs-7"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Nuevo ticket"
          ></i>
        </button>
      )}
      <button
        data-bs-toggle="dropdown"
        aria-expanded="false"
        className="btn btn-primary p-3 z-index-5 rounded-circle d-flex align-items-center justify-content-center customizer-btn"
        type="button"
        data-bs-target="#offcanvasExample"
        aria-controls="offcanvasExample"
        id="dropdownMenuButton"
        onClick={() => {
          if (filter.length > 0) {
            setFilter("");
          }
        }}
      >
        <i
          className={
            filter.length === 0 ? "ti ti-filter fs-7" : "ti ti-filter-off fs-7"
          }
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title={
            filter.length === 0
              ? "Filtrar por prioridad"
              : "Borrar filtro de prioridad"
          }
        ></i>
      </button>
      <ul
        className="bg-transparent dropdown-menu"
        aria-labelledby="dropdownMenuButton"
      >
        <div className="button-group bt-gr-priority">
          <button
            type="button"
            onClick={() => {
              if (filter !== "1") {
                setFilter("1");
              } else {
                setFilter("");
              }
            }}
            className="btn mb-1 waves-effect waves-light btn-rounded btn-primary "
          >
            <i className="ti ti-alert-triangle fs-4 me-1"></i>
            Muy baja
          </button>
          <button
            type="button"
            onClick={() => {
              if (filter !== "2") {
                setFilter("2");
              } else {
                setFilter("");
              }
            }}
            className="btn mb-1 waves-effect waves-light btn-rounded btn-info "
          >
            <i className="ti ti-alert-triangle fs-4 me-1"></i>
            Baja
          </button>

          <button
            type="button"
            onClick={() => {
              if (filter !== "3") {
                setFilter("3");
              } else {
                setFilter("");
              }
            }}
            className="btn mb-1 waves-effect waves-light btn-rounded btn-warning "
          >
            <i className="ti ti-alert-triangle fs-4 me-1"></i>
            Media
          </button>
          <button
            type="button"
            className="btn mb-1 waves-effect waves-light btn-rounded btn-high"
            onClick={() => {
              if (filter !== "4") {
                setFilter("4");
              } else {
                setFilter("");
              }
            }}
          >
            <i className="ti ti-alert-triangle fs-4 me-1"></i>
            Alta
          </button>
          <button
            onClick={() => {
              if (filter !== "5") {
                setFilter("5");
              } else {
                setFilter("");
              }
            }}
            type="button"
            className="btn mb-1 waves-effect waves-light btn-rounded btn-danger"
          >
            <i className="ti ti-alert-triangle fs-4 me-1"></i>
            Muy alta
          </button>
        </div>
      </ul>
    </Grid>
  );
};
