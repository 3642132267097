import { DateRangePicker } from "materialui-daterange-picker";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { useState } from "react";
import {
  useGetCategoriasQuery,  
  useGetEventsRepQuery,
  useGetFuentesSolicitantesQuery,
} from "../../features/api/apiYeapGo";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import * as XLSX from "xlsx";
import { useEffect } from "react";
import moment from "moment";
import { useAtom } from "jotai";
import { userAtom } from "../../features/user/userAtom";
import swal from "sweetalert2";
import {
  Priority,
  RolesAdministrador,
  Status,
  Type,
} from "../../features/Types";
import AutocompleteSelect from "../events/Autocomplete";
import { useNavigate } from "react-router-dom";

export const EventsReport = () => {
  const navigate = useNavigate();
  const [user] = useAtom(userAtom);
  const { Token, entidad } = user;
  const Auth = user.Token;
  const today = new Date();
  const oneMonthAgo = new Date(today);
  oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

  const [dateRange, setDateRange] = useState({
    startDate: oneMonthAgo,
    endDate: today,
  });

  const [open, setOpen] = useState(false);
  const [name, setName] = useState("Reporte de eventos");
  const [dataQ, setDataQ] = useState({
    fechaInicial: "",
    fechaFinal: "",
    Id_Ticket: "",
    Status: "",
    TicketName: "",
    nameAuto: "",
    TicketAuto: "",
    statusAuto: "",
  });
  const toggle = () => setOpen(!open);
  const [eventsEntidad, setEventsEntidad] = useState([]);

  /* Data */
  const { data: Fuentes } = useGetFuentesSolicitantesQuery({
    Token,
    entidad: entidad,
  });

  const { data: Categorias } = useGetCategoriasQuery({
    Token,
    entidad: entidad,
  });

  const { data, isLoading, isFetching } = useGetEventsRepQuery(
    {
      Auth,
      body: { fechaInicial: dataQ.fechaInicial, fechaFinal: dataQ.fechaFinal },
    },
    {
      pollingInterval: 1000 * 20,
    }
  );

  /* Logs */
  // console.log("eventsEntidad");
  // console.log(eventsEntidad);
  // console.log("dataQ");
  // console.log(dataQ);

  /* useEffects */
  useEffect(() => {
    if (!user.Token) {
      navigate("login");
    } else if (!user?.modulos?.includes("Descarga de datos")) {
      swal.fire({
        title: "Sin permisos suficientes",
        icon: "error",
        text: "No tiene los permisos suficientes para descargar datos",
        position: "top",
        customClass: {
          confirmButton: "custom-swal-confirm-button-error",
        },
        width: "1000px",
      });
      navigate("/");
    }
  }, [user]);
  useEffect(() => {
    if (dateRange.startDate && dateRange.endDate) {
      const today = moment().startOf("day");
      const start = moment(dateRange.startDate).startOf("day");
      const end = moment(dateRange.endDate).startOf("day");

      if (start.isSameOrBefore(today) && end.isSameOrBefore(today)) {
        setDataQ({
          ...dataQ,
          fechaInicial: start.format("YYYY-MM-DD"),
          fechaFinal: end.format("YYYY-MM-DD"),
        });
        setName(
          `Reporte ${start.format("YYYY-MM-DD")} - ${end.format("YYYY-MM-DD")}`
        );
      } else {
        swal.fire({
          title: "No puede seleccionar fechas posteriores al dia de hoy",
          icon: "error",
          position: "top",
          customClass: {
            confirmButton: "custom-swal-confirm-button-error",
            popup: "mi-popup-class",
          },
          width: "1000px",
        });
        setName(`Reporte de eventos`);
      }
    }
  }, [dateRange]);

  useEffect(() => {
    setEventsEntidad(
      data
        ?.filter((ev) => {
          return (
            parseInt(ev.Entidad) === user?.entidad ||
            user?.esMesaCompartida ||
            RolesAdministrador.map((rol) => rol.completename).includes(
              user?.Rol
            )
          );
        })
        .map((eve) => {
          return {
            ...eve,
            statusName: Status.filter((e) => e.id === eve.Status)[0]
              .completename,
          };
        })
    );
  }, [data]);

  const columns = [
    {
      field: "Id_Ticket",
      headerName: "ID",
      width: 50,
      renderCell: (params) => (
        <div style={{ color: "#cd0573" }}>{params.value}</div>
      ),
    },
    {
      field: "TicketName",
      headerName: "Título",
      width: 140,
    },
    {
      field: "Content",
      headerName: "Descripción",
      width: 250,
      renderCell: (params) => (
        <div style={{ maxHeight: "200px", overflow: "auto" }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "Status",
      headerName: "Estado",
      width: 105,
      renderCell: (params) => {
        const match = Status.find((s) => s.id == params.row.Status);
        if (match) {
          return match.completename;
        } else {
          return "";
        }
      },
    },
    {
      field: "Date_mod",
      headerName: "Actualizado",
      width: 120,
      headerClassName: "multi-line-header",
    },
    {
      field: "Fecha",
      headerName: "Apertura",
      width: 120,
      headerClassName: "multi-line-header",
    },
    {
      field: "Priority",
      headerName: "Prioridad",
      width: 90,
      renderCell: (params) => {
        const match = Priority.find((s) => s.id == params.row.Priority);
        if (match) {
          return match.completename;
        } else {
          return "";
        }
      },
    },
    {
      field: "usuarioAsignado",
      headerName: "Asignado",
      width: 110,
    },
    {
      field: "FuenteSolicitante",
      headerName: "Fuente Solicitante",
      width: 100,
      renderCell: (params) => {
        const match = Fuentes?.find(
          (s) => s.id == params.row.FuenteSolicitante
        );
        if (match) {
          return match.name;
        } else {
          return "";
        }
      },
    },
    {
      field: "Type",
      headerName: "Tipo",
      width: 120,
      renderCell: (params) => {
        const match = Type.find((s) => s.id == params.row.Type);
        if (match) {
          return match.completename;
        } else {
          return "";
        }
      },
    },
    {
      field: "Categoria",
      headerName: "Categoría",
      width: 150,
      renderCell: (params) => {
        const match = Categorias?.find((s) => s.id == params.row.Categoria);
        if (match) {
          return match.completename;
        } else {
          return "";
        }
      },
    },

    {
      field: "NombreEntidad",
      headerName: "Entidad",
      width: 100,
    },

    {
      field: "Tiempo_Invertido",
      headerName: "Tiempo invertido",
      width: 150,
      hide: true,
    },
    {
      field: "Closedate",
      headerName: "Fecha de cierre",
      width: 150,
      hide: true,
    },
    {
      field: "Solvedate",
      headerName: "Fecha de solución",
      width: 150,
      hide: true,
    },
    {
      field: "Takeintoaccountdate",
      headerName: "Fecha de toma en cuenta",
      width: 180,
      hide: true,
    },

    {
      field: "Urgency",
      headerName: "Urgencia",
      width: 150,
      hide: true,
    },
    {
      field: "Impact",
      headerName: "Impacto",
      width: 150,
      hide: true,
    },

    {
      field: "Time_to_resolve",
      headerName: "Tiempo para resolver",
      width: 180,
      hide: true,
    },
    {
      field: "Waiting_duration",
      headerName: "Duración de espera",
      width: 180,
      hide: true,
    },
    {
      field: "Is_deleted",
      headerName: "Eliminado",
      width: 150,
      hide: true,
    },
    {
      field: "location",
      headerName: "Ubicación",
      width: 150,
      hide: true,
    },
    {
      field: "tiempo_descontado",
      headerName: "Tiempo descontado",
      width: 180,
      hide: true,
    },
  ];

  /* Dowload Report */
  const downloadReport = () => {
    var wb = XLSX.utils.book_new();
    var ws = XLSX.utils.json_to_sheet(
      eventsEntidad
        ?.filter((ev) => {
          return (
            (dataQ.Id_Ticket > 0
              ? ("" + ev.Id_Ticket + "")?.includes(dataQ.Id_Ticket)
              : ("" + ev.Id_Ticket + "")?.includes(dataQ.TicketAuto)) &&
            (dataQ.Status > 0
              ? ev.Status === dataQ.Status
              : ("" + ev.statusName + "")
                  ?.toLocaleLowerCase()
                  .includes(dataQ.statusAuto.toLocaleLowerCase())) &&
            (dataQ.TicketName > 0
              ? ev.Id_Ticket === dataQ.TicketName
              : ("" + ev.name + "")
                  ?.toLocaleLowerCase()
                  .includes(dataQ.nameAuto.toLocaleLowerCase())) &&
            (dataQ.fechaInicial
              ? new Date(ev.Fecha.split(" ")[0]) >= new Date(dataQ.fechaInicial)
              : true) &&
            (dataQ.fechaFinal
              ? new Date(ev.Fecha.split(" ")[0]) <= new Date(dataQ.fechaFinal)
              : true)
          );
        })
        .map((eve) => {
          return {
            ID: eve.Id_Ticket,
            Título: eve.TicketName,
            Descripción: eve.Content,
            Estado: Status.find((s) => s.id === eve.Status)?.completename || "",
            Actualizado: eve.Date_mod,
            Apertura: eve.Fecha,
            Prioridad:
              Priority.find((s) => s.id === eve.Priority)?.completename || "",
            Asignado: eve.usuarioAsignado,
            "Fuente Solicitante":
              Fuentes?.find((s) => s.id === eve.FuenteSolicitante)?.name || "",
            Tipo: Type.find((s) => s.id === eve.Type)?.completename || "",
            Categoría:
              Categorias?.find((s) => s.id === eve.Categoria)?.completename ||
              "",
            Entidad: eve.NombreEntidad,
          };
        })
    );
    XLSX.utils.book_append_sheet(wb, ws, name);
    XLSX.writeFile(wb, `${name}.xlsx`);
  };

  return (
    <Grid
      container
      direction="row"
      spacing={2}
      justifyContent="flex-start"
      alignItems="center"
    >
      <div className="card shadow-none position-relative overflow-hidden mt-3 mb-0">
        <div className="card-body px-4 py-3 overflow-auto">
          <h4 className="fw-semibold mb-1">Descarga de datos</h4>
        </div>
      </div>
      <Grid
        item
        xs={12}
        md={12}
        sx={{
          height: 300,
          width: "100%",
          "& .super-app-theme--cell": {
            backgroundColor: "rgba(224, 183, 60, 0.3)",
          },
          "& .super-app.inactive": {
            color: "#e5007c",
          },
        }}
      >
        {isLoading || !eventsEntidad ? (
          <CircularProgress color="secondary" />
        ) : (
          <>
            <Grid container spacing={2} sx={{ mb: "2%" }}>
              <Grid item xs={3}>
                <button
                  type="button"
                  class="btn btn-primary p-3 w-100 d-flex align-items-center justify-content-center custom-btn-font"
                  data-bs-toggle="modal"
                  data-bs-target="#modalDateRange"
                  onClick={toggle}
                  fdprocessedid="tml8fm"
                >
                  <i
                    class="ti ti-calendar-time fs-7"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Rango de fechas para el reporte"
                  ></i>
                  {dataQ.fechaInicial && dataQ.fechaFinal
                    ? `[${dataQ.fechaInicial}]  a  [${dataQ.fechaFinal}]`
                    : "Rango de fechas"}
                </button>
              </Grid>{" "}
              <Grid item xs={3}>
                <AutocompleteSelect
                  options={Status}
                  data={dataQ}
                  setData={setDataQ}
                  propiedad={"Status"}
                  fullValue={"statusAuto"}
                  label="Estado"
                />
              </Grid>
              <Grid item xs={3}>
                {eventsEntidad?.length > 0 ? (
                  <AutocompleteSelect
                    options={eventsEntidad?.map((d) => {
                      return {
                        id: d.Id_Ticket,
                        completename: d.Id_Ticket,
                      };
                    })}
                    data={dataQ}
                    setData={setDataQ}
                    propiedad={"Id_Ticket"}
                    fullValue={"TicketAuto"}
                    label="ID"
                  />
                ) : (
                  <></>
                )}
              </Grid>
              <Grid item xs={3}>
                {eventsEntidad?.length > 0 ? (
                  <AutocompleteSelect
                    options={eventsEntidad?.map((d) => {
                      return {
                        id: d.Id_Ticket,
                        completename: d.TicketName,
                      };
                    })}
                    data={dataQ}
                    setData={setDataQ}
                    propiedad={"TicketName"}
                    fullValue={"nameAuto"}
                    label="Título"
                  />
                ) : (
                  <> </>
                )}
              </Grid>
            </Grid>{" "}
            <Grid
              hidden={!isFetching}
              container
              spacing={2}
              justifyContent="center" // Centra horizontalmente en el contenedor
              alignItems="center" // Centra verticalmente en el contenedor
            >
              <CircularProgress color="secondary" />
            </Grid>
            <DataGrid
              sx={{ mb: "4%", pb: "4%" }}
              localeText={{
                noRowsLabel: "No se ha encontrado datos.",
                noResultsOverlayLabel: "No se ha encontrado ningún resultado",
                toolbarColumns: "Columnas",
                toolbarColumnsLabel: "Seleccionar columnas",
                toolbarDensity: "Densidad",
                toolbarDensityComfortable: "Comodo",
                toolbarDensityStandard: "Estandar",
                toolbarDensityCompact: "Compacto",
                toolbarFilters: "Filtros",
                toolbarFiltersLabel: "Ver filtros",
                toolbarFiltersTooltipHide: "Quitar filtros",
                toolbarFiltersTooltipShow: "Ver filtros",
              }}
              columns={columns}
              getRowClassName={(params) => `row-color`}
              autoHeight
              getRowHeight={() => "auto"}
              rows={
                eventsEntidad?.filter((ev) => {
                  return (
                    (dataQ.Id_Ticket > 0
                      ? ("" + ev.Id_Ticket + "")?.includes(dataQ.Id_Ticket)
                      : ("" + ev.Id_Ticket + "")?.includes(dataQ.TicketAuto)) &&
                    (dataQ.Status > 0
                      ? ev.Status === dataQ.Status
                      : ("" + ev.statusName + "")
                          ?.toLocaleLowerCase()
                          .includes(dataQ.statusAuto.toLocaleLowerCase())) &&
                    (dataQ.TicketName > 0
                      ? ev.Id_Ticket === dataQ.TicketName
                      : ("" + ev.name + "")
                          ?.toLocaleLowerCase()
                          .includes(dataQ.nameAuto.toLocaleLowerCase())) &&
                    (dataQ.fechaInicial
                      ? new Date(ev.Fecha.split(" ")[0]) >=
                        new Date(dataQ.fechaInicial)
                      : true) &&
                    (dataQ.fechaFinal
                      ? new Date(ev.Fecha.split(" ")[0]) <=
                        new Date(dataQ.fechaFinal)
                      : true)
                  );
                }) || []
              }
              pageSize={15}
              disableSelectionOnClick
              components={{
                Toolbar: () => {
                  return (
                    <GridToolbarContainer>
                      <GridToolbarColumnsButton></GridToolbarColumnsButton>
                      <GridToolbarDensitySelector></GridToolbarDensitySelector>
                      <GridToolbarFilterButton></GridToolbarFilterButton>
                    </GridToolbarContainer>
                  );
                },
              }}
              getRowId={(row) => row.Id_Ticket}
            />
            <Dialog open={open} onClose={toggle} fullWidth={true} maxWidth="md">
              <DialogTitle id="alert-dialog-title">
                {"Selecciona el rango de fecha"}
              </DialogTitle>
              <DialogContent>
                <DateRangePicker
                  open={open}
                  toggle={toggle}
                  initialDateRange={dateRange}
                  onChange={(range) => setDateRange(range)}
                  maxDate={today}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={toggle}>Cerrar</Button>
              </DialogActions>
            </Dialog>
            <div class="bt-gr-corner">
              <button
                onClick={downloadReport}
                data-bs-toggle="dropdown"
                aria-expanded="false"
                class="btn btn-primary p-3 rounded-pill d-flex align-items-center justify-content-center float-l"
                type="button"                
                data-bs-target="#offcanvasExample"
                aria-controls="offcanvasExample"
                id="dropdownMenuButton"
              >
                <i
                  class="ti ti-file-download fs-7"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Filtrar por prioridad"
                ></i>
                Descargar Reporte
              </button>
            </div>
            <div class="bt-gr-corner">
              <button
                onClick={() => {
                  setDataQ({
                    fechaInicial: "",
                    fechaFinal: "",
                    Id_Ticket: "",
                    Status: "",
                    TicketName: "",
                    nameAuto: "",
                    TicketAuto: "",
                    statusAuto: "",
                  });
                  setName(`Reporte de eventos`);
                }}
                data-bs-toggle="dropdown"
                aria-expanded="false"
                class="btn btn-primary p-3 rounded-pill d-flex align-items-center justify-content-center float-l btn-limpiar"
                type="button"
                
                data-bs-target="#offcanvasExample"
                aria-controls="offcanvasExample"
                id="dropdownMenuButton"
              >
                <i
                  class="ti ti-filter fs-7"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Filtrar por prioridad"
                ></i>
                Limpiar Filtros
              </button>
            </div>
            
          </>
        )}
      </Grid>
    </Grid>
  );
};
