import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import React, { useState } from "react";
import swal from "sweetalert2";

function RichTextEditor({ onChange, value, required = false }) {
  const [editorIsEmpty, setEditorIsEmpty] = useState(false);  

  const handleEditorInit = (editor) => {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return new MyUploadAdapter(loader, editor);
    };
  };
  class MyUploadAdapter {
    constructor(loader, editor) {
      this.loader = loader;
      this.editor = editor;
    }

    upload() {
      return this.loader.file.then(
        (file) =>
          new Promise((resolve, reject) => {
            const contentText = this.editor.getData();
            const contentBlob = new Blob([contentText]);
            const sizeContent = contentBlob.size;
            console.log(sizeContent);
            if (sizeContent + file.size > 75000) {
              swal.fire({
                title: "Se ha excedido el tamaño del contenido",
                icon: "error",
                text: "Intente con una imagen más pequeña. El tamaño máximo son 75kb",
                position: "top",
                customClass: {
                  confirmButton: "custom-swal-confirm-button-error",
                },
                width: "1000px",
              });
              reject();
              return;
            }
            const reader = new FileReader();
            reader.onload = () => {
              const imgDataUrl = reader.result;
              resolve({
                default: imgDataUrl,
              });
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
          })
      );
    }
  }
  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    onChange(data);
    // Verifica si el editor está vacío
    setEditorIsEmpty(data.trim() === "" || !data);
  };

  return (
    <div style={{ maxWidth: "757px", wordWrap: "break-word" }}>
      {" "}
      {/* Ajusta el ancho máximo según tus necesidades */}
      <CKEditor
        editor={ClassicEditor}
        data={value || ""}
        onReady={(editor) => handleEditorInit(editor)}
        onChange={handleEditorChange}
        config={{
          toolbar: {
            items: [
              "undo",
              "redo",
              "|",
              "heading",
              "|",
              "bold",
              "italic",
              "underline",
              "alignment",
              "bulletedList",
              "numberedList",
              "indent",
              "outdent",
              "blockQuote",
              "codeBlock",
              "highlight",
              "fontFamily",
              "fontSize",
              "fontColor",
              "fontBackgroundColor",
              "removeFormat",
              "horizontalLine",
              "imageInsert",
              "insertTable",
            ],
          },
        }}
      />
      {/* Mostrar el mensaje de ayuda si el campo es obligatorio y está vacío */}
      {required && editorIsEmpty && (
        <p style={{ color: "#d32f2f" }}>Este campo es obligatorio.</p>
      )}
    </div>
  );
}

export default RichTextEditor;
