import img1 from "../../assets/images/logos/dark-logo.svg";
import img2 from "../../assets/images/logos/microsoft.png";
import axios from "axios";
import { useEffect } from "react";
import swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import { userAtom } from "../../features/user/userAtom";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "../../features/msal/authConfig";
import { callMsGraph } from "../../features/msal/graph";
const URL = process.env.REACT_APP_API_URL;
export const NewLogin = () => {
  let navigate = useNavigate();
  const [user, setUser] = useAtom(userAtom);

  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  useEffect(() => {
    if (user.Username) {
      navigate("/");
    }
  }, [user]);

  useEffect(() => {
    if (isAuthenticated) {
      RequestProfileData();
    }
  }, [isAuthenticated, instance]);

  function RequestProfileData() {
    // Silently acquires an access token which is then attached to a request for MS Graph data
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        callMsGraph(response.accessToken).then(async (response) => {
          if (response && !user.Token) {
            try {
              const res = await axios.post(`${URL}/auth`, {
                ...response,
                rol: response.jobTitle || "Administrador",
                entidad: response.department || "Todas",
              });
              const {
                Token,
                user,
                rol,
                name,
                id,
                entidad,
                esMesaCompartida,
                modulos,
              } = res.data;
              setUser({
                Token,
                Username: user,
                Rol: rol,
                Name: name,
                Identificacion: id,
                entidad: entidad,
                esMesaCompartida: esMesaCompartida,
                modulos,
              });
              swal.fire({
                title: `Bienvenido(a) ${name ? name : user.split("@")[0]}!`,
                icon: "success",
                text: "Sesión iniciada correctamente",
                position: "top",
                customClass: {
                  confirmButton: "custom-swal-confirm-button-success",
                },
                width: "1000px",
              });
            } catch (error) {
              swal.fire({
                title: "Error",
                icon: "error",
                text: error.response.data,
                position: "top",
                customClass: {
                  confirmButton: "custom-swal-confirm-button-error",
                },
                width: "1000px",
              });
              instance.logoutPopup({
                postLogoutRedirectUri: "/",
                mainWindowRedirectUri: "/",
              });
            }
          }
        });
      });
    return "f";
  }

  return (
    <>
      <div
        className="page-wrapper"
        id="main-wrapper"
        data-layout="vertical"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
      >
        <div className="position-relative overflow-hidden radial-gradient min-vh-100 d-flex align-items-center justify-content-center">
          <div className="d-flex align-items-center justify-content-center w-100">
            <div className="row justify-content-center w-100">
              <div className="col-md-8 col-lg-6 col-xxl-3">
                <div className="card mb-0">
                  <div className="card-body overflow-auto">
                    <a className="text-nowrap logo-img text-center d-block mb-5 w-100">
                      <img src={img1} width="180" alt="" />
                    </a>

                    <div className="position-relative text-center my-2">
                      <p className="mb-0 fs-4 px-3 d-inline-block bg-white text-dark z-index-5 position-relative">
                        Inicia Sesión en YeappGo!
                      </p>
                    </div>
                    <button
                      onClick={() =>
                        instance
                          .loginPopup(loginRequest)
                          .then((response) => {
                            // console.log("inicio");
                            // console.log(response);
                          })
                          .catch((e) => {})
                      }
                      className="btn btn-outline-light text-dark w-100 py-8 rounded-2 mb-5"
                    >
                      <img
                        src={img2}
                        className="me-2"
                        alt=""
                        width="35"
                        height="35"
                      />
                      Continuar con Microsoft
                    </button>
                    <div
                      id="myModal"
                      className="modal fade"
                      tabIndex="-1"
                      role="dialog"
                      aria-labelledby="myModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4 className="modal-title" id="myModalLabel">
                              {" "}
                              ¿Tienes problemas para iniciar sesión?{" "}
                            </h4>
                          </div>
                          <div className="modal-body text-center">
                            <p>
                              {" "}
                              Comunícate con el líder de I.T. para solicitarle
                              ayuda con el reestablecimiento de tus credenciales
                              de acceso.{" "}
                            </p>
                          </div>
                          <div className="modal-footer">
                            {" "}
                            <a
                              type=""
                              className="no-bg btn btn-primary"
                              data-bs-dismiss="modal"
                            >
                              {" "}
                              Entendido{" "}
                            </a>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="button-group position-relative text-center">
                      <button
                        type="button"
                        className="btn position-relative z-index-5 rounded-pill  text-primary"
                        data-bs-toggle="modal"
                        data-bs-target="#myModal"
                        fdprocessedid="tml8fm"
                      >
                        {" "}
                        ¿Tienes problemas para iniciar sesión?{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
