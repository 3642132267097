import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  autocomplete: {
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
      {
        borderColor: "#cd0573",
      },
  },
}));

export const AutocompleteSelect = ({
  options,
  label,
  data,
  setData,
  propiedad,
  fullValue,
  requerido,
  solo = false,
  inputType = "", // Nuevo parámetro para el tipo de input
}) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [isFieldEmpty, setIsFieldEmpty] = useState(false);

  useEffect(() => {
    setFilteredOptions(options);
  }, [data, options]);

  useEffect(() => {
    if (data && (data[propiedad] || data[propiedad] === 0)) {
      const option = options.find((option) => option.id === data[propiedad]);
      setSelectedOption(option);
    } else if (data && data[propiedad] !== null && data[propiedad] !== "") {
      setSelectedOption(null);
    }
    setIsFieldEmpty(!(data[propiedad] || data[propiedad] === 0));
  }, [data, options, propiedad]);

  const classes = useStyles();
  const handleChange = (event, newValue) => {
    setSelectedOption(newValue);
    if (newValue) {
      const filtered = options?.filter(
        (option) => option.id === newValue.inputValue
      );
      setFilteredOptions(filtered);
      setData((prevData) => ({
        ...prevData,
        [propiedad]: newValue.id,
      }));
    } else {
      setFilteredOptions(options);
      setData((prevData) => ({
        ...prevData,
        [propiedad]: null,
      }));
    }
  };

  const handleInputChange = (event, newInputValue) => {
    if (newInputValue) {
      setData((prevData) => ({
        ...prevData,
        [fullValue]: newInputValue,
      }));
    } else {
      setData((prevData) => ({
        ...prevData,
        [fullValue]: "",
      }));
    }
  };

  return (
    <Autocomplete
      options={filteredOptions}
      getOptionLabel={(option) => `${option.completename}`}
      value={selectedOption}
      onChange={handleChange}
      onInputChange={(event, newInputValue) => {
        handleInputChange(event, newInputValue);
      }}
      freeSolo={solo}
      autoComplete={!solo}
      classes={{ root: classes.autocomplete }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          type={inputType === "number" ? "number" : ""}
          error={isFieldEmpty && requerido}
          helperText={isFieldEmpty && requerido && "Este campo es obligatorio."}
        />
      )}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {option.completename}
        </li>
      )}
    />
  );
};

export default AutocompleteSelect;
