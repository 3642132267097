import * as React from "react";
import { useAtom } from "jotai";
import { userAtom } from "../../features/user/userAtom";
import { useMsal } from "@azure/msal-react";

import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { Users } from "../users/users";
import { Events } from "../events/Events";
import { Event } from "../events/Event";
import { EventsReport } from "../reports/EventsReport";
import { CreateEvent } from "../events/CreateEvent";
import Logout from "../login/Logout";
import img1 from "../../assets/images/logos/yeapp-logo-mini.png";
import img2 from "../../assets/images/logos/dark-logo.svg";
import img5 from "../../assets/images/logos/yeapgo-logo-dark.svg";
import img6 from "../../assets/images/logos/empresa-logo-light.png";
import img9 from "../../assets/images/svgs/icon-account.svg";
import { loginRequest } from "../../features/msal/authConfig";
import { RolesAdministrador } from "../../features/Types";
import { NewLogin } from "../login/NewLogin";
import { Roles } from "../roles/roles";
export const NewDashboard = () => {
  let navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useAtom(userAtom);
  const [yeapviewurl, setYeapviewurl] = React.useState(
    process.env.REACT_APP_YEAPPVIEW
  );
  const [yeappdourl, setYeappdourl] = React.useState(
    process.env.REACT_APP_YEAPPDO
  );
  const { instance, accounts } = useMsal();
  React.useEffect(getAccessToken, [instance, accounts]);
  function getAccessToken() {
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        setYeapviewurl(
          process.env.REACT_APP_YEAPPVIEW + "?token=" + response.accessToken
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <>
      <div
        class="page-wrapper"
        id="main-wrapper"
        data-layout="vertical"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
      >
        <aside
          className="left-sidebar"
          style={{ display: user.Token ? "block" : "none" }}
        >
          <div>
            <div className="nav-small-cap">
              <a className="logo-img nav-small-cap-icon  mt-8">
                <img src={img1} className="light-logo" width="30" alt="" />
              </a>
            </div>
            <div className="brand-logo align-items-center justify-content-between">
              <a className="hide-menu logo-img">
                <img
                  src={img2}
                  className="light-logo hide-menu"
                  width="180"
                  alt=""
                />
              </a>
              <div
                class="close-btn d-lg-none d-block sidebartoggler cursor-pointer float-r"
                id="sidebarCollapse"
              >
                <i class="ti ti-x fs-8 text-primary"></i>
              </div>
            </div>

            <nav className="sidebar-nav scroll-sidebar" data-simplebar>
              <ul id="sidebarnav">
                <li className="nav-small-cap">
                  <i className="ti ti-dots nav-small-cap-icon fs-4"></i>
                  <span className="hide-menu">YeappGo!</span>
                </li>
                {user?.modulos?.includes("Gestión de usuarios") && (
                  <li
                    className={`sidebar-item ${location.pathname.includes("usuarios")
                      ? " seleccionado"
                      : ""
                      }`}
                  >
                    <a
                      onClick={() => {
                        navigate("usuarios");
                      }}
                      className="sidebar-link"
                    >
                      <div className="round-16 d-flex align-items-center justify-content-center">
                        <i className="ti ti-user-circle"></i>
                      </div>
                      <span className="hide-menu">Gestión de Usuarios</span>
                    </a>
                  </li>
                )}
                {user?.modulos?.includes("Gestión de roles") && (
                  <li
                    className={`sidebar-item ${location.pathname.includes("roles") ? " seleccionado" : ""
                      }`}
                  >
                    <a
                      onClick={() => {
                        navigate("roles");
                      }}
                      className="sidebar-link"
                    >
                      <div className="round-16 d-flex align-items-center justify-content-center ">
                        <i className="ti ti-user-cog"></i>
                      </div>
                      <span className="hide-menu">Gestión de roles</span>
                    </a>
                  </li>
                )}
                {user?.modulos?.includes("Visualización de tickets") && (
                  <li
                    className={`sidebar-item ${location.pathname.includes("eventos")
                      ? " seleccionado"
                      : ""
                      }`}
                  >
                    <a
                      onClick={() => {
                        navigate("eventos");
                      }}
                      className="sidebar-link"
                    >
                      <div className="round-16 d-flex align-items-center justify-content-center ">
                        <i className="ti ti-align-justified"></i>
                      </div>
                      <span className="hide-menu">
                        Interfaz Unificada de Eventos
                      </span>
                    </a>
                  </li>
                )}
                {user?.modulos?.includes("Descarga de datos") && (
                  <li
                    className={`sidebar-item ${location.pathname.includes("descarga-datos")
                      ? " seleccionado"
                      : ""
                      }`}
                  >
                    <a
                      onClick={() => {
                        navigate("descarga-datos");
                      }}
                      className="sidebar-link"
                    >
                      <div className="round-16 d-flex align-items-center justify-content-center">
                        <i className="ti ti-arrow-down-right"></i>
                      </div>
                      <span className="hide-menu">Descarga de datos</span>
                    </a>
                  </li>
                )}

                {(user?.modulos?.includes("YeappView") ||
                  user?.modulos?.includes("YeappDo")) && (
                    <>
                      <li className="nav-small-cap">
                        <i className="ti ti-dots nav-small-cap-icon fs-4"></i>
                        <span className="hide-menu">YeappTools</span>
                      </li>
                      {user?.modulos?.includes("YeappView") && (
                        <li className="sidebar-item">
                          <a
                            className="sidebar-link"
                            onClick={() => {
                              window.location.href = yeapviewurl;
                            }}
                            aria-expanded="false"
                          >
                            <span>
                              <i class="icon-Recurso-21isologo-yeapp-view"></i>
                            </span>
                            <span className="hide-menu">YeappView</span>
                          </a>
                        </li>
                      )}
                      {user?.modulos?.includes("YeappDo") && (
                        <li className="sidebar-item">
                          <a
                            className="sidebar-link"
                            onClick={() => {
                              window.location.href = yeappdourl;
                            }}
                            aria-expanded="false"
                          >
                            <span>
                              <i className="icon-Recurso-19isologo-yeapp-do"></i>
                            </span>
                            <span className="hide-menu">YeappDo!</span>
                          </a>
                        </li>
                      )}
                    </>
                  )}
              </ul>
            </nav>
          </div>
        </aside>
        {!user.Token ? <NewLogin /> : <></>}
        <div
          class="body-wrapper"
          style={{ display: user.Token ? "block" : "none" }}
        >
          <header class="app-header white-background">
            <nav class="navbar navbar-expand-lg navbar-light">
              <ul class="navbar-nav">
                <li class="nav-item">
                  <a
                    class="nav-link sidebartoggler nav-icon-hover"
                    id="headerCollapse"
                  >
                    <i class="ti ti-menu-2"></i>
                  </a>
                </li>

                <li class="nav-item d-none d-lg-block p-4">
                  <a class="text-nowrap logo-img ">
                    <img src={img5} class="light-logo" width="120" alt="" />
                  </a>
                </li>
              </ul>
              <div class="navbar-collapse justify-content-end" id="navbarNav">
                <div class="d-flex align-items-center justify-content-between">
                  <ul class="navbar-nav flex-row ms-auto align-items-center justify-content-center">
                    <li class="nav-item d-none d-lg-block p-4">
                      <a class="text-nowrap logo-img ">
                        <img src={img6} class="light-logo" width="100" alt="" />
                      </a>
                    </li>
                    <li class="nav-item dropdown">
                      <a
                        class="nav-link pe-0"
                        id="drop1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <div class="d-flex align-items-center">
                          <div class="user-profile-img">
                            <i class="ti ti-user-circle profileBt"></i>
                          </div>
                        </div>
                      </a>
                      <div
                        class="dropdown-menu content-dd dropdown-menu-end dropdown-menu-animate-up"
                        aria-labelledby="drop1"
                      >
                        <div
                          class="profile-dropdown position-relative"
                          data-simplebar
                        >
                          <div class="py-3 px-7 pb-0">
                            <h5 class="mb-0 fs-5 fw-semibold">
                              Perfil de usuario
                            </h5>
                          </div>

                          <div class="message-body">
                            <a class="py-8 px-7 mt-8 d-flex align-items-center">
                              <span class="d-flex align-items-center justify-content-center bg-light rounded-1 p-6">
                                <img src={img9} alt="" width="24" height="24" />
                              </span>
                              <div class="ms-3">
                                <h6 class="mb-1 bg-hover-primary fw-semibold">
                                  {" "}
                                  {user.Username}{" "}
                                </h6>
                                <span class="mb-1 d-block text-dark">
                                  {user.Name}
                                </span>
                                <p class="mb-0 d-flex text-dark align-items-center gap-2">
                                  <i class="ti ti-user-cog fs-4"></i> {user.Rol}
                                </p>
                              </div>
                            </a>
                          </div>
                          <div class="d-grid py-4 px-7 pt-8">
                            <a
                              onClick={() => {
                                instance
                                  .logoutPopup({
                                    postLogoutRedirectUri: "/",
                                    mainWindowRedirectUri: "/",
                                  })
                                  .finally(() => setUser({}));
                              }}
                              class="btn btn-outline-primary"
                            >
                              Cerrar Sesión
                            </a>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </header>{" "}
          <div className="container-fluid">
            {user.Token ? (
              <Routes>
                <Route path="eventos/:id" element={<Event />}></Route>
                <Route path="usuarios" element={<Users />}></Route>
                <Route path="tem" element={<></>}></Route>
                <Route path="TkIbDCaknTtyZb" element={<div className="container d-flex flex-column align-items-center justify-content-center " style={{ height: '80vh' }}>
                  <h2 className=" text-center">No tiene acceso a ningún módulo. Contacte con un administrador o inicie sesión de nuevo</h2>

                </div>}></Route>
                <Route path="roles" element={<Roles />}></Route>
                <Route path="eventos" element={<Events />}></Route>
                <Route path="crearevento" element={<CreateEvent />}></Route>
                <Route path="descarga-datos" element={<EventsReport />}></Route>

                <Route path="logout" element={<Logout />}></Route>
                <Route path="go/logout" element={<Logout />}></Route>
                <Route
                  path="/*"
                  element={<Navigate to="eventos" replace />}
                ></Route>
              </Routes>
            ) : (
              <Routes>
                <Route path="*" element={<></>}></Route>
              </Routes>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
