export const Status = [
  { id: 1, completename: "Nuevo" },
  { id: 2, completename: "Asignado" },
  { id: 3, completename: "Planificado" },
  { id: 4, completename: "En espera" },
  { id: 5, completename: "Resuelto" },
  { id: 6, completename: "Cerrado" },
];

export const Urgency = [
  { id: 1, completename: "Muy baja" },
  { id: 2, completename: "Baja" },
  { id: 3, completename: "Media" },
  { id: 4, completename: "Alta" },
  { id: 5, completename: "Muy alta" },
];

export const Impact = [
  { id: 1, completename: "Muy bajo" },
  { id: 2, completename: "Bajo" },
  { id: 3, completename: "Medio" },
  { id: 4, completename: "Alto" },
  { id: 5, completename: "Muy alto" },
];

export const Type = [
  { id: 1, completename: "Incidente" },
  { id: 2, completename: "Requerimiento" },
];

export const Priority = [
  { id: 1, completename: "Muy baja" },
  { id: 2, completename: "Baja" },
  { id: 3, completename: "Media" },
  { id: 4, completename: "Alta" },
  { id: 5, completename: "Muy alta" },
  { id: 6, completename: "Mayor" },
];
export const Validation = [
  { id: 1, completename: "Vacio" },
  { id: 2, completename: "Usuario" },
  { id: 3, completename: "Grupos" },
];

export const Duration = [
  { id: 1, completename: "Vacio" },
  { id: 2, completename: "Usuario" },
  { id: 3, completename: "Grupos" },
];

export const RolesAdministrador = [
  { id: 1, completename: "Lider tecnico" },
  { id: 2, completename: "Líder TI" },
  { id: 3, completename: "GOC" },
  { id: 4, completename: "PM" },
  { id: 5, completename: "Administrador" },
  { id: 6, completename: "Líder cloud" },
  { id: 7, completename: "Líder Cloud" },
  { id: 8, completename: "GOC (Gerente de operaciones)" },
  { id: 9, completename: "Líder técnico" },
  { id: 10, completename: "Gestor de monitoreo" },
  { id: 11, completename: "NOC" },
];

export const RolesEliminacion = [
  { id: 1, completename: "Lider tecnico" },
  { id: 2, completename: "Líder TI" },
  { id: 3, completename: "GOC" },
  { id: 4, completename: "Líder cloud" },
  { id: 5, completename: "Líder cloud" },
  { id: 6, completename: "GOC (Gerente de operaciones)" },
  { id: 7, completename: "Líder técnico" },
];

export const RolesNoAdministrador = [
  { id: 1, completename: "Ing de mesa de ayuda" },
];
