import React, { useEffect } from "react";
import swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";
import {
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import {
  CircularProgress,
  Grid,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItemText,
  ListItem,  
  Checkbox,
} from "@mui/material";
import {

  useDeleteUserMutation,
  useGetRolesQuery,
  useGetModulosQuery,
  useGetRolesModuloQuery,
  useCreateRolModuloMutation,
  useDeleteRolModuloMutation,
} from "../../features/api/apiYeapGo";
import {
  DisplaySettingsOutlined,
} from "@mui/icons-material";
import { useAtom } from "jotai";
import { userAtom } from "../../features/user/userAtom";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../features/msal/authConfig";
import { listUsers } from "../../features/msal/graph";


export const Roles = () => {
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedRole, setSelectedRole] = React.useState({
    name: "",
    modules: [],
  });

  const [user, setUser] = useAtom(userAtom);
  const { Token } = user;
  const { instance, accounts } = useMsal();
  const Auth = Token;
  let navigate = useNavigate();

  /* REST */
  const [createRolModulo] = useCreateRolModuloMutation();
  const [deleteRolModulo] = useDeleteRolModuloMutation();
  const { data: roles, isLoading } = useGetRolesQuery({ Token: Auth });
  const { data: modulos } = useGetModulosQuery({ Token: Auth });
  const { data: rolesModulo } = useGetRolesModuloQuery({ Token: Auth });  

  const [dataUser, setDataUser] = useState({
    usuario: "",
    password: "",
    rol: "",
    nombres_apellidos: "",
    estado: "",
    entidad: "",
  });

  /* Formularios */
  const [openDesactivar, setOpenDesactivar] = React.useState(false);

  /* Logs */
  // console.log("userLogs");
  // console.log(userLogs);

  /* useEffects */
  React.useEffect(() => {
    if (!user.Token) {
      navigate("login");
    } else if (!user?.modulos?.includes("Gestión de roles")) {
      swal.fire({
        title: "Sin permisos suficientes",
        icon: "error",
        text: "No tiene los permisos suficientes para gestionar roles",
        position: "top",
        customClass: {
          confirmButton: "custom-swal-confirm-button-error",
        },
        width: "1000px",
      });
      navigate("/");
    }
  }, [user]);
  useEffect(() => {
    ListUsersAD();
  }, [instance, accounts]);
  useEffect(() => {
    ListUsersAD();
  }, []);
  function ListUsersAD() {
    // Silently acquires an access token which is then attached to a request for MS Graph data
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        listUsers(response.accessToken).then(async (re) => {});
      });
    return "f";
  }
  const handleModificarClick = (params) => {
    // Extract role information and modules
    const { rol } = params.row;

    const roleModules = modulos.map((m) => {
      return {
        ...m,
        status:
          rolesModulo?.filter((rm) => rm.rol === rol && rm.modulo === m.modulo)
            .length > 0
            ? true
            : false,
      };
    });
    // Update the state with selected role information
    setSelectedRole({
      name: rol,
      modules: roleModules,
    });

    setOpenDialog(true); // Open the dialog
  };

  /* table columns */
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 70,
      renderCell: (params) => (
        <div style={{ color: "#cd0573" }}>{params.value}</div>
      ),
    },
    {
      field: "rol",
      headerName: "Rol",
      width: 120,
    },

    {
      field: "modulos",
      headerName: "Modulo",
      width: 500,
      renderCell: (params) => {
        return rolesModulo
          ?.filter((m) => params.row.rol === m.rol)
          .map((m) => m.modulo)
          .join(", ");
      },
    },

    {
      field: "actions2",
      headerName: "Acciones",
      width: 160,
      sortable: false,
      renderCell: (params) => {
        const cambiarMesa = (e) => {
          e.stopPropagation();
          handleModificarClick(params);
        };

        return (
          <Button
            variant="text"
            color="secondary"
            startIcon={<DisplaySettingsOutlined />}
            onClick={cambiarMesa}
          >
            Modificar
          </Button>
        );
      },
    },
  ];

  /* handles */
  const handleClose = (action) => {
    setDataUser({});
    setOpenDesactivar(false);
    setOpenDialog(false); // Close the dialog
  };

  const guardar = () => {
    for (let index = 0; index < selectedRole.modules.length; index++) {
      const element = selectedRole.modules[index];
      if (element.status) {
        createRolModulo({
          Token: Auth,
          body: { rol: selectedRole.name, modulo: element.modulo },
        });
      } else {
        deleteRolModulo({
          Token: Auth,
          body: { rol: selectedRole.name, modulo: element.modulo },
        });
      }
      if (user.Rol === selectedRole.name)
        setUser({
          ...user,
          modulos: selectedRole.modules
            .filter((m) => m.status)
            .map((m) => m.modulo),
        });
    }
    setOpenDialog(false); // Close the dialog
  };

   

  /* Dowload Report */
  

  return (
    <>
      <Grid
        container
        direction="row"
        spacing={2}
        justifyContent="flex-start"
        alignItems="center"
      >
        <div className="card shadow-none position-relative overflow-hidden mt-3 mb-0">
          <div className="card-body px-4 py-3 overflow-auto">
            <h4 className="fw-semibold mb-1">Gestión de Roles</h4>
          </div>
        </div>

        {/* Tabla */}
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            height: 300,
            width: "100%",
            "& .super-app-theme--cell": {
              backgroundColor: "rgba(224, 183, 60, 0.3)",
            },
            "& .super-app.inactive": {
              color: "#e5007c",
            },
          }}
        >
          {isLoading ? (
            <CircularProgress color="secondary" />
          ) : (
            <>
              <DataGrid
                localeText={{
                  noRowsLabel: "No se ha encontrado datos.",
                  noResultsOverlayLabel: "No se ha encontrado ningún resultado",
                  toolbarColumns: "Columnas",
                  toolbarColumnsLabel: "Seleccionar columnas",
                  toolbarDensity: "Densidad",
                  toolbarDensityComfortable: "Comodo",
                  toolbarDensityStandard: "Estandar",
                  toolbarDensityCompact: "Compacto",
                  toolbarFilters: "Filtros",
                  toolbarFiltersLabel: "Ver filtros",
                  toolbarFiltersTooltipHide: "Quitar filtros",
                  toolbarFiltersTooltipShow: "Ver filtros",
                }}
                columns={columns}
                getRowClassName={(params) => `row-color`}
                sx={{ mb: "5%" }}
                autoHeight
                getRowHeight={() => "auto"}
                rows={roles || []}
                pageSize={15}
                disableSelectionOnClick
                components={{
                  Toolbar: () => {
                    return (
                      <GridToolbarContainer>
                        <GridToolbarColumnsButton></GridToolbarColumnsButton>
                        <GridToolbarDensitySelector></GridToolbarDensitySelector>
                        <GridToolbarFilterButton></GridToolbarFilterButton>
                      </GridToolbarContainer>
                    );
                  },
                }}
                getRowId={(row) => row.id}
                // onSelectionModelChange={(e) => setEventsID(e)}
              />
            </>
          )}
        </Grid>
      </Grid>
      {/* Dialog for modifying role */}
      <Dialog open={openDialog} onClose={handleClose}>
        <DialogTitle>{`Modificar Rol - ${selectedRole.name}`}</DialogTitle>
        <DialogContent>
          <Typography variant="h6">Seleccionar Módulos:</Typography>
          <List>
            {selectedRole.modules.map((module, index) => (
              <ListItem key={module.id}>
                <Checkbox
                  checked={module.status}
                  onChange={() => {
                    const updatedModules = [...selectedRole.modules];
                    updatedModules[index] = {
                      ...module,
                      status: !module.status,
                    };
                    setSelectedRole({
                      ...selectedRole,
                      modules: updatedModules,
                    });
                  }}
                />
                <ListItemText primary={module.modulo} />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={guardar} color="primary">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
