import React, { useState, useMemo } from "react";
import {
  List,
  ListItem,
  Typography,
  Box,
  CardHeader,
  Button,
  IconButton,
  Switch,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import RichTextEditor from "./RichTextEditor";
import {
  useApproveSolutionMutation,
  useCreateCommentMutation,
  useCreateDocumentMutation,
  useCreateSolutionMutation,
  useCreateTaskMutation,
} from "../../features/api/apiYeapGo";
import swal from "sweetalert2";
import {
  Clear,  
  InsertDriveFile,
  Lock,
} from "@mui/icons-material";
import { useAtom } from "jotai";
import { userAtom } from "../../features/user/userAtom";
import { RolesEliminacion } from "../../features/Types";

const types = {
  comments: "Comentario",
  tasks: "Tarea",
  solution: "Solución",
  documents: "Documento",
};

const useStyles = makeStyles((theme) => ({
  listItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    borderRadius: theme.spacing(2),
    backgroundColor: "#F1F0F0", // Color de fondo para mensajes enviados
    alignSelf: "flex-start",
  },
  listItemReceived: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    borderRadius: theme.spacing(2),
    backgroundColor: "#F1F0F0", // Color de fondo para mensajes enviados
    alignSelf: "flex-start",
  },
}));
const formatContent = (objeto) => {
  let content = objeto.content;
  const regex = /(<a[^>]+href="([^">]+)")|(<img[^>]+src="([^">]+)")/g;
  let match;
  const srcsToReplace = [];

  while ((match = regex.exec(content))) {
    if (match[2] && match[2].includes("docid=")) {
      srcsToReplace.push({ original: match[2], replacement: "" });
    } else if (match[4] && match[4].includes("docid=")) {
      srcsToReplace.push({ original: match[4], replacement: "" });
    }
  }

  srcsToReplace.forEach(({ original, replacement }) => {
    const docidMatch = /docid=(\d+)/.exec(original);
    if (docidMatch) {
      const docid = docidMatch[1];
      const file = objeto.files.find((file) => file.id === docid);
      if (file) {
        const fileData = new Blob([new Uint8Array(file.file.data)], {
          type: "image/png",
        });
        const fileURL = URL.createObjectURL(fileData);
        replacement = fileURL;
      }
    }
    content = content.replace(original, replacement);
  });

  return content;
};

function ResponsesList({ id, responses, open, setOpen }) {
  const [user] = useAtom(userAtom);
  const [createComment] = useCreateCommentMutation();
  const [createSolution] = useCreateSolutionMutation();
  const [approveSolution] = useApproveSolutionMutation();
  const [createTask] = useCreateTaskMutation();
  const [createDocument] = useCreateDocumentMutation();
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isPrivate, setIsPrivate] = useState(0);

  const sortedResponses = useMemo(() => {
    if (responses) {
      const responsesCopy = [...responses];
      return responsesCopy.sort(
        (a, b) => new Date(a.date_creation) - new Date(b.date_creation)
      );
    }
  }, [responses]);

  const classes = useStyles();

  const [editorValue, setEditorValue] = useState("");

  const handleEditorChange = (value) => {
    setEditorValue(value);
  };
  console.log(responses);

  const handleFileChangeDocument = (event) => {
    const files = event.target.files;
    if (files.length > 3) {
      swal.fire({
        title: "Solo se pueden cargar 3 archivos a la vez",
        icon: "warning",
        position: "top",
        customClass: {
          confirmButton: "custom-swal-confirm-button-warning",
        },
        width: "1000px",
      });
      return;
    } else {
      const fileNames = Array.from(files).map((file) => file.name);
      for (let index = 0; index < files.length; index++) {
        const formData = new FormData();
        formData.append("ticketId", id);
        formData.append("name", fileNames[index]);
        formData.append("file", files[index]);
        formData.append("answerType", "document");

        createDocument({ body: formData })
          .unwrap()
          .then(() => {
            swal.fire({
              title: "Documento(s) agregado(s)",
              icon: "success",
              position: "top",
              customClass: {
                confirmButton: "custom-swal-confirm-button-success",
              },
              width: "1000px",
            });
            setUploadedFiles([]);
            setSelectedFile([]);
          })
          .catch((err) => {
            swal.fire({
              title: "No se pudo agregar el documento",
              icon: "error",
              position: "top",
              customClass: {
                confirmButton: "custom-swal-confirm-button-error",
              },
              width: "1000px",
            });
          });
      }
    }
  };
  const aprobar = (id, ticketid) => {
    approveSolution({
      Token: user.Token,
      id,
      body: { ticketId: ticketid, userId: user.Identificacion },
    })
      .unwrap()
      .then((res) => {
        swal.fire({
          title: "Solución aprobada",
          icon: "success",
          position: "top",
          customClass: {
            confirmButton: "custom-swal-confirm-button-success",
          },
          width: "1000px",
        });
      })
      .catch((err) => {
        swal.fire({
          title: "No se pudo aprobar la solución",
          icon: "error",
          position: "top",
          customClass: {
            confirmButton: "custom-swal-confirm-button-error",
          },
          width: "1000px",
        });
      });
  };

  const handleFileChange = (event) => {
    const files = event.target.files;

    if (files.length > 3) {
      swal.fire({
        title: "Solo se pueden cargar 3 archivos a la vez",
        icon: "warning",
        position: "top",
        customClass: {
          confirmButton: "custom-swal-confirm-button-warning",
        },
        width: "1000px",
      });

      return;
    }

    setSelectedFile(files);

    const fileNames = Array.from(files).map((file) => file.name);

    setUploadedFiles(fileNames);
  };

  const handleDownloadFile = (file, name) => {
    const byteArray = new Uint8Array(file.data);
    const blob = new Blob([byteArray], { type: file.type });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = name;
    link.click();
    URL.revokeObjectURL(url);
  };
  const handleDeleteFile = (fileName) => {
    const newUploadedFiles = uploadedFiles.filter(
      (uploadedFile) => uploadedFile !== fileName
    );
    setUploadedFiles(newUploadedFiles);
  };

  return (
    <>
      <div></div>
      <div class="card  shadow-none position-relative overflow-hidden mb-0">
        <Box display="flex" flexDirection="column" height="100%">
          <Box flexGrow={1} maxHeight={400} overflow="auto">
            <List>
              {sortedResponses?.map((response, index) => (
                <ListItem
                  key={index}
                  className={
                    index % 2 === 0
                      ? classes.listItem
                      : classes.listItemReceived
                  }
                >
                  <CardHeader
                    title={
                      <>
                        {response.is_private === 1 && (
                          <Lock style={{ marginRight: "8px" }} />
                        )}

                        {types[response.type]}
                      </>
                    }
                    subheader={`Usuario: ${response.users_id} Creado: ${response.fecha}`}
                  />

                  <Box sx={{ width: "100%" }}>
                    <Typography
                      variant="body"
                      component={response.content.includes("<") ? "div" : "p"}
                      dangerouslySetInnerHTML={{
                        __html: formatContent(response),
                      }}
                    />{" "}
                    {response.type === "solution" ? (
                      response.status !== 3 ? (
                        <Button
                          hidden={
                            !RolesEliminacion.map(
                              (rol) => rol.completename
                            ).includes(user?.Rol)
                          }
                          key={index}
                          variant="contained"
                          color="secondary"
                          onClick={() => {
                            aprobar(response.id, id);
                          }}
                        >
                          Aprobar Solución
                        </Button>
                      ) : (
                        <>
                          Ticket aprobado por {response.users_id_approval} |{" "}
                          {response.date_approval}
                        </>
                      )
                    ) : (
                      <></>
                    )}
                    {response.files ? (
                      response.files.map((f, index) => (
                        <Button
                          key={index}
                          variant="contained"
                          color="primary"
                          onClick={() => handleDownloadFile(f.file, f.fileName)}
                        >
                          {f.fileName}
                        </Button>
                      ))
                    ) : (
                      <></>
                    )}
                  </Box>
                </ListItem>
              ))}
            </List>
          </Box>
        </Box>
      </div>
      <form>
        <div class="row">
          <div class="col-md-12">
            <div class="form-floating mb-2">
              <Box>
                <RichTextEditor
                  value={editorValue}
                  onChange={handleEditorChange}
                />
              </Box>
              <Box id="archivos">
                {uploadedFiles.map((fileName, index) => (
                  <Box key={index} display="flex" alignItems="center">
                    <InsertDriveFile style={{ marginRight: "5px" }} />
                    <Box component="span" sx={{ flex: "0 0 auto" }}>
                      {fileName}
                    </Box>
                    <IconButton
                      onClick={() => {
                        handleDeleteFile(fileName);
                      }}
                    >
                      <Clear />
                    </IconButton>
                  </Box>
                ))}
              </Box>
            </div>
          </div>

          <div class="col-12">
            <div class="d-md-flex align-items-center mt-3">
              <div class="ms-auto mt-3 mt-md-0">
                <button
                  class="btn btn-primary font-medium rounded-pill px-4"
                  onClick={async (e) => {
                    e.preventDefault();
                    if (editorValue.length <= 0) {
                      swal.fire({
                        title: "Debe agregar contenido al comentario",
                        icon: "warning",
                        position: "top",
                        customClass: {
                          confirmButton: "custom-swal-confirm-button-warning",
                        },
                        width: "1000px",
                      });
                      return;
                    }
                    createComment({
                      Token: "",
                      body: { ticketId: id, content: editorValue, isPrivate },
                    })
                      .unwrap()
                      .then((res) => {
                        if (uploadedFiles.length > 0) {
                          for (
                            let index = 0;
                            index < uploadedFiles.length;
                            index++
                          ) {
                            const formData = new FormData();
                            formData.append("ticketId", res.id);
                            formData.append("name", uploadedFiles[index]);
                            formData.append("file", selectedFile[index]);
                            formData.append("answerType", "comment");

                            createDocument({ body: formData })
                              .unwrap()
                              .then(() => {
                                swal.fire({
                                  title: "Documento(s) agregado(s)",
                                  icon: "success",
                                  position: "top",
                                  customClass: {
                                    confirmButton:
                                      "custom-swal-confirm-button-success",
                                  },
                                  width: "1000px",
                                });
                                setUploadedFiles([]);
                                setSelectedFile([]);
                              })
                              .catch((err) => {
                                swal.fire({
                                  title: "No se pudo agregar el documento",
                                  icon: "error",
                                  position: "top",
                                  customClass: {
                                    confirmButton:
                                      "custom-swal-confirm-button-error",
                                  },
                                  width: "1000px",
                                });
                              });
                          }
                        }
                        swal.fire({
                          title: "Comentario agregado",
                          icon: "success",
                          position: "top",
                          customClass: {
                            confirmButton: "custom-swal-confirm-button-success",
                          },
                          width: "1000px",
                        });
                        setEditorValue("");
                        setIsPrivate(0);
                      })
                      .catch((err) => {
                        swal.fire({
                          title: "No se pudo agregar el comentario",
                          icon: "error",
                          position: "top",
                          customClass: {
                            confirmButton: "custom-swal-confirm-button-error",
                          },
                          width: "1000px",
                        });
                      });
                  }}
                >
                  <div class="d-flex align-items-center">
                    <i class="ti ti-calendar-plus me-2 fs-4"></i>
                    Añadir comentario
                  </div>
                </button>
                <button
                  class="btn btn-primary font-medium rounded-pill px-4"
                  onClick={async (e) => {
                    e.preventDefault();
                    if (editorValue.length <= 0) {
                      swal.fire({
                        title: "Debe agregar contenido a la tarea",
                        icon: "warning",
                        position: "top",
                        customClass: {
                          confirmButton: "custom-swal-confirm-button-warning",
                        },
                        width: "1000px",
                      });
                      return;
                    }
                    createTask({
                      Token: "",
                      body: { ticketId: id, content: editorValue, isPrivate },
                    })
                      .unwrap()
                      .then((res) => {
                        if (uploadedFiles.length > 0) {
                          for (
                            let index = 0;
                            index < uploadedFiles.length;
                            index++
                          ) {
                            const formData = new FormData();
                            formData.append("ticketId", res.id);
                            formData.append("name", uploadedFiles[index]);
                            formData.append("file", selectedFile[index]);
                            formData.append("answerType", "task");

                            createDocument({ body: formData })
                              .unwrap()
                              .then(() => {
                                swal.fire({
                                  title: "Documento(s) agregado(s)",
                                  icon: "success",
                                  position: "top",
                                  customClass: {
                                    confirmButton:
                                      "custom-swal-confirm-button-success",
                                  },
                                  width: "1000px",
                                });
                                setUploadedFiles([]);
                                setSelectedFile([]);
                              })
                              .catch((err) => {
                                swal.fire({
                                  title: "No se pudo agregar el documento",
                                  icon: "error",
                                  position: "top",
                                  customClass: {
                                    confirmButton:
                                      "custom-swal-confirm-button-error",
                                  },
                                  width: "1000px",
                                });
                              });
                          }
                        }
                        swal.fire({
                          title: "Tarea agregada",
                          icon: "success",
                          position: "top",
                          customClass: {
                            confirmButton: "custom-swal-confirm-button-success",
                          },
                          width: "1000px",
                        });
                        setEditorValue("");
                        setIsPrivate(0);
                      })
                      .catch((err) => {
                        swal.fire({
                          title: "No se pudo agregar la tarea",
                          icon: "error",
                          position: "top",
                          customClass: {
                            confirmButton: "custom-swal-confirm-button-error",
                          },
                          width: "1000px",
                        });
                      });
                  }}
                >
                  <div class="d-flex align-items-center">
                    <i class="ti ti-calendar-plus me-2 fs-4"></i>
                    Añadir Tarea
                  </div>
                </button>{" "}
                <label
                  style={{ color: "#333", fontSize: "16px" }}
                  className="form-check-label"
                >
                  <Switch
                    checked={isPrivate}
                    onChange={() => setIsPrivate(!isPrivate)}
                    color="secondary"
                  />
                  Comentario o tarea privada
                </label>
                <button
                  class="btn btn-primary font-medium rounded-pill px-4"
                  onClick={async (e) => {
                    e.preventDefault();
                    if (editorValue.length <= 0) {
                      swal.fire({
                        title: "Debe agregar contenido a la solución",
                        icon: "warning",
                        position: "top",
                        customClass: {
                          confirmButton: "custom-swal-confirm-button-warning",
                        },
                        width: "1000px",
                      });
                      return;
                    }
                    createSolution({
                      Token: "",
                      body: { ticketId: id, content: editorValue },
                    })
                      .unwrap()
                      .then((res) => {
                        if (uploadedFiles.length > 0) {
                          for (
                            let index = 0;
                            index < uploadedFiles.length;
                            index++
                          ) {
                            const formData = new FormData();
                            formData.append("ticketId", res.id);
                            formData.append("name", uploadedFiles[index]);
                            formData.append("file", selectedFile[index]);
                            formData.append("answerType", "solution");

                            createDocument({ body: formData })
                              .unwrap()
                              .then(() => {
                                swal.fire({
                                  title: "Documento(s) agregado(s)",
                                  icon: "success",
                                  position: "top",
                                  customClass: {
                                    confirmButton:
                                      "custom-swal-confirm-button-success",
                                  },
                                  width: "1000px",
                                });
                                setUploadedFiles([]);
                                setSelectedFile([]);
                              })
                              .catch((err) => {
                                swal.fire({
                                  title: "No se pudo agregar el documento",
                                  icon: "error",
                                  position: "top",
                                  customClass: {
                                    confirmButton:
                                      "custom-swal-confirm-button-error",
                                  },
                                  width: "1000px",
                                });
                              });
                          }
                        }
                        swal.fire({
                          title: "Solución agregada",
                          icon: "success",
                          position: "top",
                          customClass: {
                            confirmButton: "custom-swal-confirm-button-success",
                          },
                          width: "1000px",
                        });
                        setEditorValue("");
                        setIsPrivate(0);
                      })
                      .catch((err) => {
                        swal.fire({
                          title: "No se pudo agregar la solución",
                          icon: "error",
                          text: "Debe asegurarse de que no exista previamente una solución",
                          position: "top",
                          customClass: {
                            confirmButton: "custom-swal-confirm-button-error",
                          },
                          width: "1000px",
                        });
                      });
                  }}
                >
                  <div class="d-flex align-items-center">
                    <i class="ti ti-calendar-plus me-2 fs-4"></i>
                    Añadir solución
                  </div>
                </button>
                <button
                  class="btn btn-primary font-medium rounded-pill px-4"
                  onClick={(e) => {
                    e.preventDefault();
                    const fileInput = document.createElement("input");
                    fileInput.type = "file";
                    fileInput.multiple = true; // Permite la selección múltiple
                    fileInput.addEventListener(
                      "change",
                      handleFileChangeDocument
                    );
                    fileInput.click();
                  }}
                >
                  <div class="d-flex align-items-center">
                    <i class="ti ti-calendar-plus me-2 fs-4"></i>
                    Añadir archivo
                  </div>
                </button>
                <button
                  class="btn btn-primary font-medium rounded-pill px-4"
                  onClick={(e) => {
                    e.preventDefault();
                    const fileInput = document.createElement("input");
                    fileInput.type = "file";
                    fileInput.multiple = true; // Permite la selección múltiple
                    fileInput.addEventListener("change", handleFileChange);
                    fileInput.click();
                  }}
                >
                  <div class="d-flex align-items-center">
                    <i class="ti ti-calendar-plus me-2 fs-4"></i>
                    Anexar Archivo
                  </div>
                </button>
                <a
                  type=""
                  class="no-bg btn rounded-pill btn-light-info px-3"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    setUploadedFiles([]);
                  }}
                >
                  <i class="ti ti-arrow-back me-2 fs-4"></i> Volver{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default ResponsesList;
