import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useAtom } from "jotai";
import { userAtom } from "./features/user/userAtom";
import { NewDashboard } from "./components/dashboard/NewDashboard";

function App() {  
  
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<NewDashboard />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
