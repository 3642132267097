import ReactApexChart from "react-apexcharts";
import {  
  useGetTicketLogChartQuery,
} from "../../features/api/apiYeapGo";
import { useAtom } from "jotai";
import { userAtom } from "../../features/user/userAtom";
import moment from "moment";

export const ComponenteBehavior = ({ idTicket, nombreEntidad }) => {
  const [user] = useAtom(userAtom);
  const { Token } = user;
  const { data: timelineData } = useGetTicketLogChartQuery({
    Token,
    id: idTicket,
  });

  const state = {
    series: [
      {
        data: timelineData
          ? timelineData
              .filter((e) => e.x !== "Cerrado")
              .map((e) => {
                // Suma 5 horas (300 minutos) a las fechas
                return {
                  ...e,
                  y: [
                    moment(e.y[0]).add(-300, "minutes").valueOf(),
                    moment(e.y[1]).add(-300, "minutes").valueOf(),
                  ],
                };
              })
          : [],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "rangeBar",
      },
      plotOptions: {
        bar: {
          horizontal: true,
          distributed: true,
          dataLabels: {
            hideOverflowingLabels: false,
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val, opts) {
          var a = moment(val[0]);
          var b = moment(val[1]);
          var diff = b.diff(a, "days");
          if (diff < 1) {
            // Si la diferencia es menor a un día, muestra horas o minutos
            var hours = b.diff(a, "hours");
            if (hours > 0) {
              return hours + (hours > 1 ? " horas" : " hora");
            } else {
              var minutes = b.diff(a, "minutes");
              return minutes + (minutes > 1 ? " minutos" : " minuto");
            }
          }
          return diff + (diff > 1 ? " días" : " día");
        },
        style: {
          colors: ["#f3f4f5", "#fff"],
        },
      },
      tooltip: {
        shared: false,
        x: {
          formatter: function (val) {
            return moment(val).add(300, "minutes").format("DD MMM HH:mm");
          },
        },
      },
      xaxis: {
        type: "datetime",
      },
      yaxis: {
        show: true,
      },
      grid: {
        row: {
          colors: ["#f3f4f5", "#fff"],
          opacity: 1,
        },
      },

      annotations: {
        xaxis:
          timelineData?.filter((e) => e.x === "Cerrado").length > 0
            ? [
                {
                  x: moment(
                    timelineData.filter((e) => e.x === "Cerrado")[0].y[0]
                  )
                    .add(-300, "minutes")
                    .valueOf(),
                  strokeDashArray: 0,
                  borderColor: "#775DD0",
                  label: {
                    borderColor: "#775DD0",
                    style: {
                      color: "#fff",
                      background: "#775DD0",
                    },
                    text: "Cerrado",
                  },
                },
              ]
            : [],
      },
    },
  };
  return (
    <ReactApexChart
      options={state.options}
      series={state.series}
      type="rangeBar"
      height={350}
    />
  );
};
